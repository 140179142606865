export const toTitleCase = (str) => {
  str = str.toLowerCase();
  return toTitleCaseWithSeparator(
    toTitleCaseWithSeparator(toTitleCaseWithSeparator(str, "-"), " "),
    " - "
  );
};

export const toTitleCaseWithSeparator = (str, separator) => {
  return str
    .split(separator)
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(separator);
};

export const pluralize = (singular, amount) => {
  if (amount === 1) {
    return singular;
  }

  if (singular.endsWith("y") && !/[aeiou]y$/.test(singular)) {
    return singular.slice(0, -1) + "ies";
  }

  if (
    singular.endsWith("s") ||
    singular.endsWith("sh") ||
    singular.endsWith("ch") ||
    singular.endsWith("x") ||
    singular.endsWith("z")
  ) {
    return singular + "es";
  }

  return `${singular}s`;
};
