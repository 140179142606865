import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { venueSearchPropType } from "../../../Helpers/VenueSearchTypes";

const RadioBtn = ({
  venueSearchTypeRadio,
  text,
  name,
  validate,
  checked,
  action,
}) => (
  <div
    className="btn-group-toggle col-sm-12 col-md-4 my-2"
    data-toggle="button"
  >
    <label
      className={`btn btn-outline-tertiary btn--radio ${
        checked ? "active" : ""
      }`}
      htmlFor={`${name}${venueSearchTypeRadio}`}
    >
      <Field
        name={name}
        value={venueSearchTypeRadio}
        id={`${name}${venueSearchTypeRadio}`}
        validate={validate}
        component="input"
        type="radio"
        onChange={action}
      />
      <span className="w-100 text-center">{text}</span>
    </label>
  </div>
);
RadioBtn.propTypes = {
  venueSearchTypeRadio: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  validate: PropTypes.arrayOf(PropTypes.func).isRequired,
  action: PropTypes.func.isRequired,
};

const errorDisplay = ({ meta: { touched, error } }) => (
  <>
    {touched && error && <div className="form-error form-group">{error}</div>}
  </>
);
errorDisplay.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
};

const VenueSearchTypeRadioField = ({
  types,
  name,
  validate,
  selected,
  action,
}) => (
  <>
    <Field name={name} component={errorDisplay} />
    <div className="form-group form-row">
      {types.map((type) => (
        <RadioBtn
          name={name}
          validate={validate}
          venueSearchTypeRadio={type.value}
          key={type.value}
          text={type.name}
          checked={selected === type.value}
          action={action}
        />
      ))}
    </div>
  </>
);

VenueSearchTypeRadioField.propTypes = {
  types: PropTypes.arrayOf(venueSearchPropType).isRequired,
  name: PropTypes.string.isRequired,
  validate: PropTypes.arrayOf(PropTypes.func).isRequired,
  selected: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
};

export default VenueSearchTypeRadioField;
