import React from "react";
import PropTypes from "prop-types";
import Select, { components as selectComponents } from "react-select";
import customStyles, {
  getSelectStyles,
} from "@gbli-events/common/src/Helpers/ReactSelectStyle";
import { FieldInputProps } from "@gbli-events/common/src/Constants/ReduxFormPropTypes";
import { isEqual } from "lodash";
import themePropType from "@gbli-events/common/src/Models/Theme";
import { GBLI_THEME } from "../../Constants/themes/gbliTheme";

const SelectList = ({
  options,
  input,
  meta,
  disabled,
  placeholder,
  searchable,
  inputMode,
  pattern,
  resetValue,
  theme,
  components,
}) => {
  // Options are defined in terms of their { value: , label: } object
  // this pulls the option whose value property equals the value argument
  const getOptionFromValue = (value) =>
    options.reduce(
      (carry, current) => (isEqual(current.value, value) ? current : carry),
      null
    );
  // Manage the value from props
  // if the input data includes a value, use it
  // else if there is a placeholder, don't impose a value
  // else the value is the first option
  const value = () =>
    getOptionFromValue(input.value) ||
    (!placeholder && options[0]) ||
    undefined;

  const Input = (props) => (
    <selectComponents.Input
      {...props}
      inputMode={inputMode}
      pattern={pattern}
    />
  );

  const selectStyles = theme
    ? getSelectStyles(theme, Boolean(meta.touched && meta.error))
    : customStyles;

  return (
    <Select
      options={options}
      id={input.name}
      disabled={disabled}
      className="w-100"
      autoSize
      value={value()}
      onChange={(v) => {
        input.onChange(v?.value || resetValue);
      }}
      onBlur={() => input.onBlur()}
      styles={selectStyles}
      backspaceRemovesValue
      isClearable={resetValue !== undefined}
      isSearchable={searchable}
      placeholder={placeholder}
      components={{
        Input,
        IndicatorSeparator: () => null,
        DropdownIndicator: ({ selectProps: { menuIsOpen } }) => (
          <i
            style={{ color: theme.components.Select.borderColorActive }}
            className={`fa fa-chevron-${
              menuIsOpen ? "up" : "down"
            } c-gold mr-3`}
          ></i>
        ),
        ...components,
      }}
    />
  );
};

SelectList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ).isRequired,
  input: FieldInputProps.isRequired,
  theme: themePropType,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  searchable: PropTypes.bool,
  inputMode: PropTypes.string,
  pattern: PropTypes.string,
  resetValue: PropTypes.any,
  components: PropTypes.objectOf(PropTypes.func),
};

SelectList.defaultProps = {
  disabled: false,
  placeholder: undefined,
  searchable: false,
  theme: GBLI_THEME,
};

export default SelectList;
