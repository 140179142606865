export const TERRORISM = "terrorism";
export const PERSONAL_AND_ADVERTISING_LIMIT = "personal-and-advertising-limit";
export const PRODUCTS_AND_COMPLETED_OPERATIONS =
  "products-and-completed-operations";
export const DAMAGE_TO_RENTED_PREMISES = "damage-to-rented-premises";
export const HOST_LIQUOR = "host-liquor";
export const MEDICAL = "medical";
export const WAIVER_OF_TRANSFER_RIGHTS = "waiver-of-transfer-rights";

export const DAMAGE_TO_RENTED_PREMISES_VALUES = [300000, 500000, 1000000];
export const DAMAGE_TO_RENTED_PREMISES_LABELS = [
  "$300,000",
  "$500,000",
  "$1,000,000 (default)",
];
