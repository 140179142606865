let instance = null;

const getInstance = () => {
  if (!instance) {
    const { google } = window;
    instance = new google.maps.Geocoder();
  }
  return instance;
};

const geoCode = (params) => {
  return new Promise((resolve, reject) => {
    const geocoder = getInstance();
    geocoder.geocode(params, (results, status) => {
      if (status === "OK") {
        if (results.length < 1) {
          reject(new Error("NO RESULTS"));
        }
        resolve(results[0]);
      } else {
        reject(status);
      }
    });
  });
};

const geoCodeByPlaceId = (placeId) => {
  return geoCode({ placeId });
};

export { geoCode, geoCodeByPlaceId };
