export const GBLI_THEME = {
  themeSlug: "gbli-events",
  title: "GBLI Special Events",
  favicons: [
    { rel: "shortcut icon", href: "/favicon.ico" },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/favicons/apple-touch-icon.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/favicons/favicon-32x32.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "/favicons/favicon-16x16.png",
    },
    {
      rel: "mask-icon",
      href: "/favicons/safari-pinned-tab.svg",
      color: "#0070a7",
    },
    { rel: "shortcut icon", href: "/favicons/favicon.ico" },
  ],
  breakpoints: {
    xxs: "0",
    xs: "375px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
  },
  palette: {
    primary: {
      light: "#dbc091",
      main: "#9e8146",
      dark: "#836b3a",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ecf0f7",
      main: "#466cb7",
      dark: "#0d3a68",
      contrastText: "#fff",
    },
    error: {
      light: "#ecf0f7",
      main: "#0d3a68",
    },
  },
  typography: {
    fonts: {
      primary: "Raleway, sans-serif",
      secondary: "Hind, sans-serif",
    },
    h1: ``,
    h2: `
      color: #9e8146;
    `,
    h3: `
      color: #0d3a68;
    `,
    h4: ``,
    h5: ``,
    h6: ``,
  },
  globalStyles: `
    .summary-table {
      &__item-value {
        color: #0d3a68;
        font-weight: bold;
      }
    }

    .limits {
      &__limit-info {
        color: #0d3a68;
        font-weight: 600;
      }
    }

    .form-step-nav {
      &__start-over {
        border-color: #9e8146;
        color:#9e8146;
        &:hover {
          background-color: #9e8146;
          border-color: #9e8146;
        }
      }
    }

    // Datepicker styles needed here for overlay

    .rdp {
      &-day {

        &_selected:not(.rdp-day_disabled) {
          background-color: transparent !important;
          .rdp-day-content {
            background-color: #9e8146 !important;
          }
        }

        &:hover:not(.rdp-day_selected, .rdp-day_disabled),
        &_in-hover-range:not(.rdp-day_selected, .rdp-day_disabled) {
          .rdp-day-content {
            border: 2px solid #9e8146 !important;
            background-color: #f5f2ec !important;
            outline-color: #9e8146 !important;
          }
        }
      }


      &-button {
        &:focus-visible,
        &:focus {
          background-color: #f5f2ec !important;
          border-color: #9e8146 !important;
          outline-color: #9e8146 !important;
        }
      }
    }
  `,
  components: {
    Button: {
      customCss: `
        text-transform: uppercase;
        letter-spacing: 3.4px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        text-align: center;
        vertical-align: middle;
        padding: 0.5rem 0.75rem;
        font-size: 0.875rem;
        line-height: 1.5;

        a, a:hover {
          font-weight: 600;
        }

        font-family: Hind, sans-serif;
        &:disabled {
          color: #757575;
          background-color: #f5f2ec;
          border-color: #f5f2ec;
          opacity: 1;
        }
        font-weight: 600;`,
    },
    ButtonToggle: {
      backgroundColor: "#fff",
      backgroundColorActive: "#fcf9f4",
      borderColor: "#9e8146",
      borderColorActive: "#9e8146",
      color: "#9e8146",
      colorActive: "#9e8146",
      borderRadius: "0",
    },
    CheckboxField: {
      activeColor: "#9e8146",
      activeSecondaryColor: "#dfd2b7",
      customCss: "",
    },
    DateRangePickerField: {
      dateSelectedColor: "#9e8146",
      customCss: `
        input:disabled {
          background-color: #e9ecef !important;
        }
      `,
    },
    EventCard: {
      outlineColor: "#466cb7",
      outlineColorActive: "#9e8146",
      borderRadius: "0",
      backgroundColor: "#fafafa",
      backgroundColorActive: "#eeeeee",
      dividerColor: "#9e8146",
      color: "#231f20",
      customCss: `
        .event-card__select-btn {
          border-color: #0d3a68;
          color: #0d3a68;
          &:active,
          &:hover:not(.event-card__select-btn--selected) {
            background-color: #fafafa;
            color: #0d3a68;
          }

          &--selected {
            background-color: #fafafa;
            border-color: #9e8146;
            color: #9e8146;
          }
      }`,
    },
    Input: {
      customCss: `
        &:required {
          border-left: 3px solid #9e8146;
        }
      `,
    },
    InputGroup: {
      customCss: `i { color: #bdbdbd }`,
    },
    PriceSummaryFooter: {
      customCss: `
        background: #ecf0f7;
        color: #0d3a68;
      `,
    },
    QuoteSummaryFloater: {
      customCss: `
        .quote-summary-floater__arrow-close {
          color:#dbc091;
        }
      `,
    },
    RadioFieldGroup: {
      checkRadius: "50%",
      borderRadius: "50%",
      radioColor: "#757575",
      radioColorActive: "#757575",
      radioColorSecondaryActive: "#e0e0e0",
    },
    Select: {
      borderColor: "#e0e3e2",
      borderColorActive: "#9e8146",
      indicatorColor: "#9e8146",
      borderRadius: "0",
      fontFamily: "Raleway, sans-serif",
      minHeight: "42px",
      customCss: `
        .select-list-field {
          &.required > div:not(.form-error) > div {
            border-left: 3px solid #9e8146;
            border-radius: 3px;
          }
        }
      `,
    },
    SelectListField: {
      customCss: `
        &.select-list-field {
          &.required > div:not(.form-error) > div {
            border-left: 3px solid #9e8146;
            border-radius: 3px;
          }
        }
      `,
    },
  },
};
