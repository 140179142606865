import React from "react";
import PropTypes from "prop-types";

const DropzoneInput = ({ accept, onFiles, getFilesFromEvent }) => {
  return (
    <div className="dropzone__input-wrap">
      <label className="dropzone__input-main">
        <div className="dropzone__main-label">Drop file anywhere to upload</div>
        <input
          style={{ display: "none" }}
          type="file"
          accept={accept}
          multiple
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              onFiles(chosenFiles);
            });
          }}
        />
      </label>
      <label className="btn btn-primary dropzone__browse">
        Browse
        <input
          style={{ display: "none" }}
          type="file"
          accept={accept}
          multiple
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              onFiles(chosenFiles);
            });
          }}
        />
      </label>
    </div>
  );
};

DropzoneInput.propTypes = {
  accept: PropTypes.bool.isRequired,
  onFiles: PropTypes.func.isRequired,
  getFilesFromEvent: PropTypes.func.isRequired,
};

export default DropzoneInput;
