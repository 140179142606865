export const stringHelpers = {
  // Capitalize first letter of string
  firstToUpper: (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  // 1000000 to 1,000,000
  commaSeparatedNumber: (string) => {
    return new Intl.NumberFormat("en-US", { style: "decimal" }).format(string);
  },

  // Takes an array of names and splits them with space
  fullName: (names) => names.join(" "),
  insuranceContactName: (coverage) =>
    coverage.insuranceContactType === "Person"
      ? stringHelpers.fullName([
          coverage.insuranceContactFirstName,
          coverage.insuranceContactLastName,
        ])
      : coverage.insuranceContactCompanyName,
  renterName: (coverage) =>
    coverage.renterAddressType === "Person"
      ? stringHelpers.fullName([
          coverage.renterFirstName,
          coverage.renterLastName,
        ])
      : coverage.renterCompanyName,

  // Takes address parts and displays them as a readable address
  address: (address1, address2, city, state, country, zip) =>
    `${address1 ? `${address1}, ` : ""} 
    ${address2 ? `${address2}, ` : ""} 
    ${city ? `${city}, ` : ""} 
    ${state} 
    ${zip}
    ${country ? `${country}` : ""}`,
};
