import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SearchFieldComponent from "./SearchFieldComponent";

const SearchField = ({ label, fieldName, change, totalText, value }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showIcon, setShowIcon] = useState(true);

  useEffect(() => {
    setSearchTerm(value);
  }, [setSearchTerm, value]);

  return (
    <>
      <SearchFieldComponent
        label={label}
        fieldName={fieldName}
        change={change}
        totalText={totalText}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        showIcon={showIcon}
        setShowIcon={setShowIcon}
      />
    </>
  );
};

SearchField.propTypes = {
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  totalText: PropTypes.string.isRequired,
  value: PropTypes.string,
};

SearchField.defaultProps = {
  value: "",
};

export default SearchField;
