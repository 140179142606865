import StyledAppErrorMessage from "./StyledAppErrorMessage";

const MaintenanceModeMessage = () => (
  <StyledAppErrorMessage className="container">
    <div className="flex-column">
      <h1>Site under maintenance</h1>
      <div className="mb-2 mt-4 h4 mx-auto">
        Site is currently under maintenance. Please{" "}
        <button
          className="undecorate-btn"
          onClick={() => {
            window.location.reload();
          }}
        >
          check again
        </button>
        in a few minutes.
      </div>
      <div className="mb-4 mt-2 col-lg-8 mx-auto h4">
        Thank you for your patience.
      </div>
    </div>
  </StyledAppErrorMessage>
);

export default MaintenanceModeMessage;
