import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import Debounce from "../../Helpers/Debounce";
import { refreshLoginInterval } from "../../Actions/actions";

const debounce = new Debounce({ period: 5000 });

const ActivityListener = () => {
  const dispatch = useDispatch();
  const lastActivityTimeRef = useRef(Date.now());

  useEffect(() => {
    const onActivity = () =>
      debounce.do(() => {
        lastActivityTimeRef.current = Date.now();
        dispatch(refreshLoginInterval(lastActivityTimeRef.current));
      });

    document.addEventListener("keydown", onActivity);
    document.addEventListener("mousedown", onActivity);

    onActivity();

    return () => {
      document.removeEventListener("keydown", onActivity);
      document.removeEventListener("mousedown", onActivity);
    };
  });

  return null;
};

export default ActivityListener;
