import React from "react";
import PropTypes from "prop-types";
import { FieldArray } from "redux-form";
import { venuePropType } from "../../Helpers/VenueModel";
import ContactFields from "./FormElements/ContactFields";
import { ContactCards } from "@jauntin/react-ui";

const Contact = ({ editing, venue }) => {
  return (
    <>
      {editing ? (
        <FieldArray name="contacts" component={ContactFields} />
      ) : (
        <ContactCards
          contacts={venue.contacts}
          cardTitle="Venue Contact"
          className="mb-4"
          cardClassName="w-100 mt-4"
          headerClassName="bg-transparent font-weight-bold"
        />
      )}
    </>
  );
};

Contact.propTypes = {
  editing: PropTypes.bool.isRequired,
  venue: venuePropType.isRequired,
};

export default Contact;
