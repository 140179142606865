import cc from "card-validator";
import { dateHelpers } from "@jauntin/utilities";
import { isRequiredCondition } from "@gbli-events/common/src/Helpers/UnderwritingQuestions";
import { cybersouceTestCards } from "../../../frontend/src/Helpers/CyberSourceCardTypes";
import {
  GBLI_EMAIL_EVENTS,
  GBLI_PHONE_EVENTS_TITLE,
} from "../Constants/shared";
import { validators } from "@jauntin/utilities";
import { isEmpty } from "lodash";

const { numberOfDays } = dateHelpers;

// Quick list of simple validators grabbed from Redux Form's site.
// Seriously needs tests and filling out with further functions

export const required = validators.required;
export const arrayNotEmpty = validators.arrayNotEmpty;
export const notZero = validators.notZero;
export const maxLength = validators.maxLength;
export const minLength = validators.minLength;
export const number = validators.number;
export const minValue = validators.minValue;
export const maxValue = validators.maxValue;
export const oneOf = validators.oneOf;
export const email = validators.email;
export const alphaNumeric = validators.alphaNumeric;
export const phoneNumber = validators.phoneNumber;
export const creditCardCvv = validators.creditCardCvv;
export const creditCardNumber = (value) =>
  validators.creditCardNumber(
    process.env.REACT_APP_ALLOW_TEST_CARDS === "true" ? [] : cybersouceTestCards
  )(value);

export const eligibility = (value) =>
  value !== "no" ? `This must be no` : undefined;

export const requiredDates = (value) => {
  const dates = Object.values(value || {});
  for (let i = 0; i < dates.length; i += 1) {
    if (!dates[i]) return "Required";
  }
  return undefined;
};

const validateDigits = (value, digits) => {
  return value && new RegExp(`^([0-9]{${digits}})$`, "i").test(value)
    ? undefined
    : `Invalid, must be ${digits} digits`;
};

export const zipCode = (value) => {
  if (!value) return undefined;

  return validateDigits(value, 5);
};

export const zipCodePlus4 = (value) =>
  new RegExp(`^[0-9]{5}(-[0-9]{4})?$`).test(value)
    ? undefined
    : `Enter a valid zip code`;
export const fourDigits = (value) => validateDigits(value, 4);
export const fiveDigits = (value) => validateDigits(value, 5);
// personCompany just checks for person or company, it is not used for field level validation,
// BUT it IS required to make the validation work when toggling. Check reducer is...Valid funcs.
export const personCompany = (value) =>
  value === "Person" ? undefined : "company";
export const sameContact = (value) =>
  value === "yes" ? undefined : "not the same contact as insured";
export const samePayee = (value) =>
  value === "yes" ? undefined : "not the same billing address as contact";
export const dateInput = (value) =>
  value && /^((0|1)\d{1})\/((0|1|2)\d{1})\/((19|20)\d{2})/g.test(value)
    ? undefined
    : "Invalid date, must be MM/DD/YYYY";

export const creditCardDate = (ccMonthCtrl, ccYearCtrl) => (value, allValues) =>
  !Boolean(allValues[ccMonthCtrl]) ||
  !Boolean(allValues[ccYearCtrl]) ||
  cc.expirationDate(`${allValues[ccMonthCtrl]}/${allValues[ccYearCtrl]}`)
    .isValid
    ? undefined
    : "Invalid expiration date";

export const atLeastOneDayMustBePicked = (_, allValues) =>
  allValues.daysOfWeekField.reduce((prev, curr) => (curr ? true : prev), false)
    ? undefined
    : "At least one day must be picked";
export const checked = (value) =>
  value
    ? undefined
    : "Please click checkbox to acknowledge policy cancellation";
export const facilityCodeValidation = (value) =>
  value && !/^[a-zA-Z0-9]{3,4}$/i.test(value)
    ? "Only alphanumeric characters"
    : undefined;
export const venueCodeValidation = (value) =>
  value && !/^\d{3,5}$/i.test(value) ? "Only numbers" : undefined;
export const providersValidation = (values) => {
  let inputErrors = [];
  let hasErrors = false;
  let providerError = false;
  const totalDays = numberOfDays(values.eventDates);

  const errors = {};
  const providersList = [
    "performersList",
    "goodsVendorsList",
    "foodVendorsList",
    "exhibitorsList",
  ];

  const providerCallback = (p, c) => {
    if (
      c.name &&
      parseInt(c.days, 10) > 0 &&
      parseInt(c.days, 10) <= totalDays
    ) {
      inputErrors.push({});
      return p;
    }

    const error = {};

    if (!c.name || c.name.trim() === "") error.name = " ";
    if (!parseInt(c.days, 10)) error.days = " ";
    inputErrors.push(error);

    hasErrors = true;
    return true;
  };

  for (let i = 0; i < providersList.length; i += 1) {
    if (values[providersList[i]] && values[providersList[i]].length)
      providerError = values[providersList[i]].reduce(providerCallback, false);

    if (providerError) {
      errors[providersList[i]] = inputErrors;

      let providerName = "performer";
      if (providersList[i] === providersList[1]) providerName = "goods vendor";
      if (providersList[i] === providersList[2])
        providerName = "food and/or beverage vendor";
      if (providersList[i] === providersList[3]) providerName = "exhibitor";

      let errorDays = false;
      let errorName = false;
      errors[providersList[i]].forEach((item) => {
        if (item.days) errorDays = true;
        if (item.name) errorName = true;
      });
      if (errorName)
        errors[
          providersList[i]
        ]._error = `Please enter a name for each ${providerName}.`;
      if (errorDays)
        errors[
          providersList[i]
        ]._error = `Please enter at least 1 day for each ${providerName}.`;
    }

    inputErrors = [];
    providerError = false;
  }

  if (hasErrors) return errors;
  return undefined;
};

export const underwritingQuestionsValidation = (values) => {
  const errors = {};
  const underwritingQuestions = values.underwritingQuestions || [];
  const questionsArrayErrors = [];

  const allAnswered = underwritingQuestions
    .filter((underwritingQuestion) =>
      isRequiredCondition(underwritingQuestions)(underwritingQuestion)
    )
    .every((underwritingQuestion) => !!underwritingQuestion.answer);
  if (!allAnswered) {
    errors.underwritingQuestions = { _error: "All questions must be answered" };
  }

  underwritingQuestions.forEach((question, index) => {
    const questionErrors = {};

    if (
      !question.answer ||
      !isRequiredCondition(underwritingQuestions)(question)
    )
      return;

    const errorIncorrect = oneOf(question.allowedValues)(question.answer);
    if (errorIncorrect) {
      questionErrors.answer = errorIncorrect;
      questionsArrayErrors[index] = questionErrors;
    }
  });

  if (questionsArrayErrors.length) {
    errors.underwritingQuestions = questionsArrayErrors;
  }

  return Object.values(errors).length ? errors : undefined;
};

export const requiredEvent = (value) => {
  return !value?.label || !value?.value?.identifier ? "Required" : undefined;
};

export const firstCharacterIsNumber = (value) =>
  /^\d$/.test(value.charAt(0)) ? undefined : "First character is not a number";

export const minAttendees = (value) =>
  minValue(1)(value)
    ? `Unfortunately, based on the information provided a policy can’t be bound online. Please contact us at ${GBLI_PHONE_EVENTS_TITLE} or ${GBLI_EMAIL_EVENTS} for further assistance`
    : undefined;

export const maxAttendees = (value) =>
  maxValue(500)(value)
    ? `Unfortunately, based on the information provided a policy can’t be bound online. Please contact us at ${GBLI_PHONE_EVENTS_TITLE} or ${GBLI_EMAIL_EVENTS} for further assistance`
    : undefined;

export const venueAddressRequired = (value) =>
  !value || isEmpty(value.address) ? "Required" : undefined;

export const selectionRequired = (value) =>
  !value ? "Please make a selection" : undefined;

export const accepted = (value) =>
  !value ? "Please accept the following" : undefined;

export const glRequired = (value) =>
  !value?.occurrence || !value?.aggregate ? "Required" : undefined;
