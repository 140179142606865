// General debouncer
// Delays execution for a set time period.
// If you submit multiple requests within a single period, only the last one gets executed.
// sample use:
// const db = new Debounce({ period: 1500 });
// function myAsyncCall() {
//   db.do(() => dispatch(someAction));
// }
class Debounce {
  timeOut = null;

  callback = null;

  args = null;

  period = 2000;

  constructor({ period }) {
    this.period = period;
  }

  do(callback, ...args) {
    this.callback = callback;
    this.args = args;
    if (this.timeOut) {
      return;
    }
    this.timeOut = window.setTimeout(() => {
      this.callback(...this.args);
      this.timeOut = null;
    }, this.period);
  }
}
export default Debounce;
