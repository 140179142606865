import { dateHelpers } from "@jauntin/utilities";
import { subDays, addDays, isAfter } from "date-fns";

export const updateBlockedOptionalVenue = (
  state,
  { index, value: isBlocked }
) => ({
  ...state,
  values: {
    ...state.values,
    optionalVenues: state.values.optionalVenues.map((venue, i) => {
      if (i === index) return { ...venue, isBlocked };

      return venue;
    }),
  },
});

export const updateOptionalVenueError = (
  state,
  { index, value: genericServerError }
) => ({
  ...state,
  values: {
    ...state.values,
    optionalVenues: state.values.optionalVenues.map((venue, i) => {
      if (i === index) return { ...venue, genericServerError };

      return venue;
    }),
  },
});

export const confirmOptionalVenue = (
  state,
  { index, placeId, address, addressComponents, utcOffset, addressId }
) => ({
  ...state,
  values: {
    ...state.values,
    optionalVenues: state.values.optionalVenues.map((venue, i) => {
      if (i === index) {
        return {
          ...venue,
          selectedAddressId: addressId,
          selectedPlaceId: placeId,
          selectedPlaceAddress: address,
          selectedPlaceUtcOffset: utcOffset,
          validVenue: true,
          selectedPlaceAddressComponents: {
            ...addressComponents,
            companyName:
              addressComponents.companyName !== addressComponents.address1 ||
              (addressComponents.companyName &&
                addressComponents.address1 &&
                addressComponents.companyName === addressComponents.address1 &&
                !(addressComponents.companyName.charAt(0) < 10))
                ? addressComponents.companyName
                : null,
          },
        };
      }

      return venue;
    }),
  },
});

export const getSetupAndTeardownDates = ({
  startDate,
  endDate,
  allowPastDates = false,
  timezone,
}) => {
  startDate = dateHelpers.timezonedDateToLocalDate(startDate, timezone);
  endDate = dateHelpers.timezonedDateToLocalDate(endDate, timezone);

  const today = new Date();

  const eventSetupDates = startDate
    ? [1]
        .filter(
          (day) => allowPastDates || isAfter(subDays(startDate, day), today)
        )
        .map((day) => ({
          key: dateHelpers.localDateToTimezonedDate(subDays(startDate, day)),
          selected: false,
        }))
    : [];

  const eventTeardownDates = endDate
    ? [1].map((day) => ({
        key: dateHelpers.localDateToTimezonedDate(addDays(endDate, day)),
        selected: false,
      }))
    : [];

  return { eventSetupDates, eventTeardownDates };
};

export const updateEventDatesFromOtherFields = ({
  daysOfWeekField,
  eventDateRangeField,
  timezone,
}) => {
  let { startDate, endDate } = { ...eventDateRangeField };
  if (!startDate && !endDate) {
    return [];
  }

  const ignoredDays =
    (daysOfWeekField.length &&
      dateHelpers.pickedToBlockedDays(daysOfWeekField)) ||
    [];

  if (!startDate) {
    startDate = endDate;
  } else if (!endDate) {
    endDate = startDate;
  }

  return dateHelpers.getDaysInRange({
    startDate,
    endDate,
    ignoredDays,
    timezone,
  });
};
