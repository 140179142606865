import React from "react";
import PropTypes from "prop-types";
import { isObject } from "lodash";
import { Alert, Col, Form } from "react-bootstrap";
import parse from "html-react-parser";

const AlertStatus = ({
  show,
  setShowAlert,
  message,
  status,
  dismissible,
  onClose,
}) => {
  return (
    <Alert
      onClose={() => {
        onClose();
        setShowAlert(false);
      }}
      show={show}
      variant={status}
      dismissible={dismissible}
    >
      <Form.Row>
        <Col xs="auto" className="mb-auto">
          {status === "success" && (
            <i className="fal fa-check-circle icon--large" />
          )}
          {status === "danger" && (
            <i className="fal fa-exclamation-circle icon--large" />
          )}
          {status === "warning" && (
            <i className="fal fa-exclamation-triangle icon--large" />
          )}
        </Col>
        <Col>{isObject(message) ? message : parse(message)}</Col>
      </Form.Row>
    </Alert>
  );
};

AlertStatus.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  status: PropTypes.string.isRequired,
  dismissible: PropTypes.bool,
  onClose: PropTypes.func,
};
AlertStatus.defaultProps = {
  dismissible: false,
  onClose: () => {},
};

export default AlertStatus;
