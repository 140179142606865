import React from "react";
import PropTypes from "prop-types";
import { Alert } from "react-bootstrap";

const StickyAlertMessage = ({ show, message }) => (
  <Alert show={show} variant="info" className="mx-auto mb-2 mt-3">
    <div className="form-row">
      <div className="col-auto">
        <i className="fal fa-info-circle icon--large" />
      </div>
      <div className="col">{message}</div>
    </div>
  </Alert>
);
StickyAlertMessage.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default StickyAlertMessage;
