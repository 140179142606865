const nonAddressTypes = [
  "route",
  "country",
  "locality",
  "sublocality",
  "political",
  "neighborhood",
];
export const addressTypeIsForAddress = (addressTypes) =>
  !addressTypes.some((a) => nonAddressTypes.includes(a));

export const isValidAddress = (
  Address1,
  City,
  State,
  Country,
  Zip,
  AddressType
) => {
  return !!(
    // Evaluate address type if it is supplied
    (
      (!AddressType || addressTypeIsForAddress([AddressType])) &&
      Address1 &&
      City &&
      State &&
      Country &&
      Zip
    )
  );
};
