/**
 * Query string serializer that behaves similarly to jQuery's param.
 * Creates query string parameters from nested objects and arrays
 * e.g. { a: { b: 'c', d: ['e', 'f'] } } => a[b]=c&a[d][0]=e&a[d][1]=f
 * Adapted from https://stackoverflow.com/a/53528135
 */
const param = (params, keys = [], isArray = false) => {
  const p = Object.keys(params)
    .map((key, index) => {
      const val = params[key];

      if (
        Object.prototype.toString.call(val) === "[object Object]" ||
        Array.isArray(val)
      ) {
        if (Array.isArray(params)) {
          keys.push(index);
        } else {
          keys.push(key);
        }
        return param(val, keys, Array.isArray(val));
      }
      let tKey = encodeURIComponent(key);

      if (keys.length > 0) {
        const tKeys = isArray ? keys : [...keys, key];
        tKey = tKeys.reduce((str, k) => {
          return str === ""
            ? encodeURIComponent(k)
            : `${str}[${encodeURIComponent(k)}]`;
        }, "");
      }
      if (isArray) {
        return `${tKey}[]=${encodeURIComponent(val)}`;
      }
      return `${tKey}=${encodeURIComponent(val)}`;
    })
    .join("&");

  keys.pop();
  return p;
};

export default param;
