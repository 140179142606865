import styled from "styled-components";
import { TextInput } from "@jauntin/react-ui";

const StyledTextInput = styled(TextInput)`
  input {
    ${({ theme }) => theme.components.Input?.customCss}
  }
`;

export default StyledTextInput;
