import React from "react";
import { reduxForm, FieldArray } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose } from "redux";
import { customContactsForm } from "../../constants";
import {
  coveragePropType,
  contactsPropType,
} from "../../Helpers/CoverageModel";
import ContactFields from "./FormElements/ContactFields";

const CustomContact = ({
  status,
  contacts,
  deleteCoverageContact,
  coverage,
}) => {
  return (
    <>
      <FieldArray
        name="customContacts"
        component={ContactFields}
        status={status}
        contacts={contacts}
        coverage={coverage}
        deleteCoverageContact={deleteCoverageContact}
      />
    </>
  );
};

CustomContact.propTypes = {
  status: PropTypes.string.isRequired,
  contacts: contactsPropType.isRequired,
  deleteCoverageContact: PropTypes.func.isRequired,
  coverage: coveragePropType.isRequired,
};

const mapStateToProps = (state) => {
  const { currentCoverage } = state.policies;
  return {
    status: currentCoverage.status,
    contacts: currentCoverage.customContacts,
    initialValues: {
      customContacts: currentCoverage.customContacts,
      emailCustomContact: [true, true],
    },
  };
};

const CustomContacts = compose(
  connect(mapStateToProps),
  reduxForm({ form: customContactsForm })
)(CustomContact);

export default CustomContacts;
