/**
 * Service wrapper for Users API
 */
class UserService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getPaginatedUsers = (page = null, perPage = null) => {
    const apiParams = {};
    if (page) {
      apiParams.page = page;
    }
    if (perPage) {
      apiParams.perPage = perPage;
    }

    return this.api.get({
      location: "/users",
      params: apiParams,
    });
  };

  postAddNewUser = (data) =>
    this.api.post({
      location: "/users",
      body: data,
    });

  deleteUser = (user) => this.api.delete({ location: `/users/${user.id}` });
}

export default UserService;
