import React from "react";
import PropTypes from "prop-types";
import { dateHelpers } from "@jauntin/utilities";
import { TimezoneFormatted } from "@jauntin/react-ui";

const DateList = ({ dates }) => (
  <div className="py-2">
    {dates.map((date) => (
      <div key={dateHelpers.dateOnlyStringFormat(date)} className="d-flex">
        <button type="button" className="btn btn-link py-0" />
        <span className="my-auto">
          <TimezoneFormatted
            date={date}
            format={dateHelpers.LETTERS_AND_NUMBERS_FORMAT}
          />
        </span>
      </div>
    ))}
  </div>
);
DateList.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default DateList;
