import styled from "styled-components";
import { DateRangePicker } from "@jauntin/react-ui";

const dateSelectedColor = ({ theme }) => {
  return theme.components.DateRangePickerField?.dateSelectedColor;
};

const StyledDateRangePickerField = styled(DateRangePicker)`
  input {
    ${({ theme }) => theme.components.Input?.customCss}
  }

  ${({ theme }) => theme.components.DateRangePickerField?.customCss}

  .rdp-input-focus {
    border-color: ${dateSelectedColor} !important;
    box-shadow: 0 0 0 1px ${dateSelectedColor};
  }
`;

export default StyledDateRangePickerField;
