import { change, reset, untouch } from "redux-form";
import * as qs from "query-string";
import { ConnectedRouter } from "@jauntin/react-ui";
import API from "../Helpers/API";
import CoverageService from "../Helpers/CoverageService";
import AppInfoService from "../Helpers/AppInfoService";
import { getUrl, LOGIN_PAGE, isProtectedPage } from "../Helpers/URLParser";
import AuthService from "@gbli-events/common/src/Helpers/AuthService";
import StateTaxService from "../Helpers/StateTaxService";
import {
  KENTUCKY_STATE_CODE,
  LOGINTIMEOUTINTERVAL,
  LOGINTIMEOUTPOLLINTERVAL,
  VENUE_REASSIGN_UPDATED,
  allContactsForm,
  DEFAULT_PRODUCER_CODE,
  policyFormName,
} from "../constants";
import ProducerService from "../Helpers/ProducerService";
import AssociationService from "../Helpers/AssociationService";
import FacilityService from "../Helpers/FacilityService";
import VenueService from "../Helpers/VenueService";
import ContactsService from "../Helpers/ContactsService";

/**
 *
 * ACTION TYPES
 *
 */

export const getAppInfo = () => async (dispatch) => {
  try {
    const { data } = await new AppInfoService(new API()).getAppInfo();
    dispatch(setDataLoaded(data));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const getSortableEventTypes = () => async (dispatch) => {
  try {
    const { data } = await new AppInfoService(
      new API()
    ).getSortableEventTypes();
    dispatch(setSortableEventTypes(data));
  } catch (err) {
    dispatch(errorResponse(err));
  }
};

export const SET_DATA_LOADED = "SET_DATA_LOADED";
export const setDataLoaded = (payload) => ({ type: SET_DATA_LOADED, payload });

export const SET_SORTABLE_EVENT_TYPES = "SET_SORTABLE_EVENT_TYPES";
export const setSortableEventTypes = (payload) => ({
  type: SET_SORTABLE_EVENT_TYPES,
  payload,
});

// An error has been returned from the server
export const API_ERROR = "API_ERROR";
export const errorResponse = (err) => {
  return (dispatch) => {
    // We are unauthenticated. Go to login screen with returnUrl
    if (
      err.response &&
      (err.response.status === 401 || err.response.status === 419) &&
      isProtectedPage(window.location.pathname)
    ) {
      const queryString = qs.stringify({ returnUrl: window.location.pathname });
      const url = `${getUrl(LOGIN_PAGE)}?${queryString}`;
      return dispatch(ConnectedRouter.push(url));
    }
    return dispatch({ type: API_ERROR, error: err });
  };
};

export const UPDATE_LOGIN_STATUS = "UPDATE_LOGIN_STATUS";
export const updateLoginStatus = (status) => {
  return { type: UPDATE_LOGIN_STATUS, payload: status };
};

export const LOGIN_LOCKED_OUT = "LOGIN_LOCKED_OUT";
export const setLoginLockedOut = (status) => {
  return { type: LOGIN_LOCKED_OUT, payload: status };
};

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const setCurrentUser = (user) => {
  return { type: SET_CURRENT_USER, payload: user };
};

export const RESET_CURRENT_USER = "RESET_CURRENT_USER";
export const resetCurrentUser = () => ({ type: RESET_CURRENT_USER });

// Check login status
export const loginStatus = () => {
  return (dispatch) => {
    new AuthService(new API())
      .authLogin()
      .then((response) => {
        if (response && response.status === 200) {
          dispatch(updateLoginStatus(true));
          dispatch(setCurrentUser(response.data));
        }
      })
      .catch((err) => {
        dispatch(updateLoginStatus(false));
        dispatch(resetCurrentUser());
        dispatch(errorResponse(err));
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    new AuthService(new API())
      .authLogout()
      .then((result) => {
        if (result.status === 200) {
          dispatch(updateLoginStatus(false));
          dispatch(resetCurrentUser());
          dispatch(ConnectedRouter.push(getUrl(LOGIN_PAGE)));
        }
      })
      .catch((err) => {
        dispatch(errorResponse(err));
        dispatch(updateLoginStatus(false));
        dispatch(resetCurrentUser());
        dispatch(ConnectedRouter.push(getUrl(LOGIN_PAGE)));
      });
  };
};

// Login timeout. Maintains a global activity check. If no activity has been dispatched after the timeoutInterval,
// manually log out the user
let loginTimeout;
let lastActivityTime;

const handleTimeout = (dispatch) => {
  clearInterval(loginTimeout);
  dispatch(logout());
};

export const refreshLoginInterval = (newLastActivityTime) => {
  lastActivityTime = newLastActivityTime;
  return (dispatch) => {
    clearInterval(loginTimeout);
    loginTimeout = setInterval(() => {
      if (Date.now() - lastActivityTime >= LOGINTIMEOUTINTERVAL) {
        handleTimeout(dispatch);
      }
    }, LOGINTIMEOUTPOLLINTERVAL);
  };
};

// Request Coverage search results from the server, and dispatch returned data
export const UPDATE_POLICIES_TABLE = "UPDATE_POLICIES_TABLE";
const updatePoliciesTable = (data, timezone) => {
  return { type: UPDATE_POLICIES_TABLE, payload: { data, timezone } };
};
export const UPDATE_SEARCH_TERM = "UPDATE_SEARCH_TERM";
const updateSearchTerm = (data) => {
  return { type: UPDATE_SEARCH_TERM, payload: data };
};
export const getSearchPolicies = (searchInput, page, perPage) => {
  return (dispatch, getState) => {
    dispatch(updateSearchTerm(searchInput));
    const {
      policies,
      app: { timezone },
    } = getState();
    const params = {};
    if (policies.showUnknownVenuesOnly) {
      params.excludeKnownVenues = 1;
    }
    new CoverageService(new API())
      .getPoliciesSearchResults(searchInput, page, perPage, params)
      .then(
        (response) => dispatch(updatePoliciesTable(response.data, timezone)),
        (err) => dispatch(errorResponse(err))
      );
  };
};

export const SET_PAGE = "SET_PAGE";
export const setPage = (newPage) => {
  return { type: SET_PAGE, newPage };
};

// Get a single Coverage from the server, and dispatch returned record
export const UPDATE_POLICY_DATA = "UPDATE_POLICY_DATA";
export const updatePolicyData = (data, timezone) => {
  return { type: UPDATE_POLICY_DATA, payload: { data, timezone } };
};
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const toggleModal = (modal, open) => {
  return { type: TOGGLE_MODAL, payload: { modal, open } };
};
export const getPolicy = (id) => {
  return (dispatch, getState) => {
    const {
      app: { timezone },
    } = getState();
    return new CoverageService(new API())
      .getCoverage(id)
      .then((response) => {
        dispatch(updatePolicyData({ ...response.data }, timezone));
      })
      .then(() => dispatch(toggleModal("update", false)))
      .catch((err) => dispatch(errorResponse(err)));
  };
};

// Get US states from server and dispatch data
export const STORE_STATES = "STORE_STATES";
const storeStates = (data) => ({
  type: STORE_STATES,
  payload: data,
});
export const loadStates = () => {
  return (dispatch) => {
    return new AppInfoService(new API())
      .getStates()
      .then((response) => {
        dispatch(storeStates(response.data));
      })
      .catch((err) => dispatch(errorResponse(err)));
  };
};

export const CONFIRM_UPDATE_POLICY = "CONFIRM_UPDATE_POLICY";
export const confirmUpdatePolicy = (quoteDifference) => {
  return { type: CONFIRM_UPDATE_POLICY, payload: { quoteDifference } };
};

export const SET_IS_PRISTINE = "SET_IS_PRISTINE";
export const setIsPristine = (data) => {
  return { type: SET_IS_PRISTINE, payload: { data } };
};

export const SET_IS_FORM_PRISTINE = "SET_IS_FORM_PRISTINE";
export const setIsFormPristine = (data) => {
  return { type: SET_IS_FORM_PRISTINE, payload: { data } };
};

// Ask the server for original and new quote for comparison
// Dispatches COMFIRM_UPDATE_POLICY and API_ERROR
export const calculateChanges = (quoteValues, isFormPristine) => (dispatch) =>
  new CoverageService(new API())
    .getCalculateQuoteDifferences(quoteValues)
    .then((response) => {
      const originalQuote = response.data.originalQuote.total.amount;
      const newQuote = response.data.newQuote.total.amount;
      dispatch(confirmUpdatePolicy(Math.abs(originalQuote - newQuote)));
    })
    .then(() => {
      if (isFormPristine) {
        dispatch(setIsPristine(true));
      } else {
        dispatch(setIsPristine(false));
      }
    })
    .catch((err) => {
      dispatch(setIsPristine(true));
      dispatch(errorResponse(err));
    });

// Update a Redux form with some values. e.g. to automatically fill out a form with fresh values
// () => { reset('formName'); setFormValues('formName', { field1: 'hello!' });
export const SET_FORM_VALUES = "SET_FORM_VALUES";
export const setFormValues = (formName, values) => {
  return { type: SET_FORM_VALUES, payload: { formName, values } };
};
export const SET_REQUEST_RESET_FORM_SUCCESS_MESSAGE =
  "SET_REQUEST_RESET_FORM_SUCCESS_MESSAGE";
export const setRequestResetFormSuccessMessage = (value) => ({
  type: SET_REQUEST_RESET_FORM_SUCCESS_MESSAGE,
  value,
});
export const SET_RESET_FORM_SUCCESS_MESSAGE = "SET_RESET_FORM_SUCCESS_MESSAGE";
export const setResetFormSuccessMessage = (value) => ({
  type: SET_RESET_FORM_SUCCESS_MESSAGE,
  value,
});
export const CLEAR_CONTACT_FORM_CHECKBOX = "CLEAR_CONTACT_FORM_CHECKBOX";
export const clearContactFormCheckbox = (formName, value) => {
  return {
    type: CLEAR_CONTACT_FORM_CHECKBOX,
    payload: { formName, value },
  };
};
export const DELETE_DATE = "DELETE_DATE";
export const deleteDate = (date) => {
  return { type: DELETE_DATE, payload: { date } };
};

export const RESET_ALL_VENUE_FIELDS = "RESET_ALL_VENUE_FIELDS";
export const resetAllVenueFields = (data) => {
  return { type: RESET_ALL_VENUE_FIELDS, payload: { data } };
};

export const RESET_VENUE_FIELDS = "RESET_VENUE_FIELDS";
export const resetVenueFields = (data) => {
  return { type: RESET_VENUE_FIELDS, payload: { data } };
};

export const clearVenueSearchForm = (formName, data) => {
  return (dispatch) => {
    dispatch(change(formName, "venue", null));
    dispatch(resetAllVenueFields(data));
  };
};

export const SET_GLL_TO_BASE_GLL = "SET_GLL_TO_BASE_GLL";
export const setGllToBaseGll = () => {
  return { type: SET_GLL_TO_BASE_GLL };
};

// The user has selected a place from the venue Google Autocomplete part
export const SELECT_PLACE = "SELECT_PLACE";
export const selectPlace = (
  placeId,
  placeName,
  address,
  addressComponents,
  utcOffset
) => ({
  type: SELECT_PLACE,
  placeId,
  placeName,
  address,
  addressComponents,
  utcOffset,
});

export const clearVenueMunicipalityCode = (formName) => {
  return (dispatch) => {
    dispatch(change(formName, "venueMunicipalityCode", null));
  };
};

export const getTaxRegions = (
  stateCode,
  city,
  formName,
  setHasRegionalTax,
  setTaxRegions,
  setIsValidCity,
  setHasLookedUpTax
) => {
  return (dispatch) => {
    new StateTaxService(new API())
      .getTaxRegions(stateCode, city)
      .then((response) => {
        setHasLookedUpTax(true);
        if (response.data.length > 0) {
          setTaxRegions(response.data);
          setIsValidCity(true);
        } else {
          setIsValidCity(false);
        }
        return response.data;
      })
      .then((regions) => {
        if (regions.length > 0) {
          setHasRegionalTax(true);
          dispatch(change(formName, "venueMunicipalityCode", ""));
          dispatch(change(formName, "tempMunicipalityCode", ""));
          regions.forEach((region) => {
            if (region.name.toLowerCase() === city.toLowerCase()) {
              dispatch(change(formName, "venueMunicipalityCode", region.code));
              dispatch(change(formName, "tempMunicipalityCode", region.code));
            }
          });
        }
      })
      .catch((err) => {
        setIsValidCity(true);
        setHasLookedUpTax(false);
        dispatch(errorResponse(err));
      });
  };
};

export const shouldLookupTax = (
  stateCode,
  city,
  formName,
  setHasRegionalTax,
  setTaxRegions,
  setIsValidCity,
  setHasLookedUpTax
) => {
  return (dispatch) => {
    new StateTaxService(new API())
      .confirmRegionalTax(stateCode)
      .then((response) => {
        setHasRegionalTax(false);
        if (response.data.shouldLookupTax) {
          dispatch(
            getTaxRegions(
              stateCode,
              city,
              formName,
              setHasRegionalTax,
              setTaxRegions,
              setIsValidCity,
              setHasLookedUpTax
            )
          );
        }
        if (stateCode !== KENTUCKY_STATE_CODE) {
          setIsValidCity(true);
          setHasLookedUpTax(true);
        }
      })
      .catch((err) => {
        setIsValidCity(true);
        setHasLookedUpTax(false);
        dispatch(errorResponse(err));
      });
  };
};

export const checkValidState = (venueState, usStates) => {
  let validState = false;
  usStates.some((e) => {
    if (e.code === venueState) {
      validState = true;
    }
    return e.code === venueState;
  });
  return validState;
};

export const SET_VALID_GLL = "SET_VALID_GLL";
export const setValidGll = (data) => {
  return { type: SET_VALID_GLL, payload: data };
};

export const SET_VALID_DRP = "SET_VALID_DRP";
export const setValidDrp = (data) => {
  return { type: SET_VALID_DRP, payload: data };
};

export const INCLUDE_WAIVER_OF_TRANSFER_RIGHTS =
  "INCLUDE_WAIVER_OF_TRANSFER_RIGHTS";
export const includeWaiverOfTransferRights = (data) => {
  return { type: INCLUDE_WAIVER_OF_TRANSFER_RIGHTS, payload: data };
};

export const CLEAR_ADDITIONAL_INSURED_FIELDS =
  "CLEAR_ADDITIONAL_INSURED_FIELDS";
export const clearAdditionalInsuredFields = (data) => {
  return { type: CLEAR_ADDITIONAL_INSURED_FIELDS, payload: data };
};

export const SET_KNOWN_VENUE = "SET_KNOWN_VENUE";
export const setKnownVenue = (data) => {
  return { type: SET_KNOWN_VENUE, payload: data };
};

export const checkAndSetVenue = (placeId) => {
  return (dispatch) => {
    new CoverageService(new API())
      .getPresets(placeId)
      .then((response) => {
        const { data } = response;
        dispatch(setValidGll(data.venue.glLimits));
        dispatch(setValidDrp(data.venue.drpLimits));
        dispatch(
          includeWaiverOfTransferRights(
            data.venue.additionalCoverage.find(
              (ac) => ac.type === "waiver-of-transfer-rights"
            )?.value[0] === true
          )
        );
        dispatch(setKnownVenue(data));
        dispatch(setIsPristine(false));
        if (data.places) {
          dispatch(getUtcOffset(data.places[0].placeId));
        } else {
          dispatch(getCoordsForUtc(data.venue.zip));
        }
      })
      .catch((err) => {
        dispatch(setValidGll());
        dispatch(setValidDrp());
        dispatch(includeWaiverOfTransferRights(false));
        if (err.response.status === 422) {
          dispatch(setIsPristine(true));
        } else {
          dispatch(setIsPristine(false));
          dispatch(clearAdditionalInsuredFields());
          dispatch(getDefaultProducer());
        }
        dispatch(errorResponse(err));
      });
  };
};

export const updateVenueInputSelect = (selected, formName) => {
  return (dispatch) =>
    dispatch(change(formName, "venueSearchTypeRadio", selected));
};

export const UPDATE_VENUE_CODE_VALIDITY = "UPDATE_VENUE_CODE_VALIDITY";
export const updateVenueCodeValidity = (data) => {
  return { type: UPDATE_VENUE_CODE_VALIDITY, payload: data };
};

export const SET_UTC_OFFSET = "SET_UTC_OFFSET";
export const setUtcOffset = (data) => {
  return { type: SET_UTC_OFFSET, payload: data };
};

export const getUtcOffset = (placeId) => {
  return (dispatch) => {
    const { google } = window;
    const map = new google.maps.Map(document.createElement("div"));
    const request = {
      placeId,
      fields: ["utc_offset_minutes"],
    };

    function callback(place, status) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        dispatch(setUtcOffset(place.utc_offset_minutes));
      } else {
        dispatch(setUtcOffset());
      }
    }

    const service = new google.maps.places.PlacesService(map);
    service.getDetails(request, callback);
  };
};

export const getCoordsForUtc = (zip) => {
  return (dispatch) => {
    // get placeId from google
    new CoverageService(new API(false))
      .getCoordinates(zip)
      .then((response) => {
        const placeId = response.data.results[0].place_id;
        if (placeId) {
          dispatch(getUtcOffset(placeId));
        }
      })
      .catch((err) => {
        dispatch(setUtcOffset());
        dispatch(errorResponse(err));
      });
  };
};

export const searchByVenueCode = (
  facilityCode,
  venueCode,
  setVenueCodeChecked,
  setValidVenueCode,
  shouldLookupTax,
  setHasRegionalTax,
  setTaxRegions,
  setIsValidCity,
  setHasLookedUpTax
) => {
  return (dispatch) => {
    new CoverageService(new API())
      .getPresetsByVenueCode(facilityCode, venueCode)
      .then((response) => {
        const { data } = response;
        setVenueCodeChecked(true);
        const venueCodeValid = !!Object.keys(data).length;
        setValidVenueCode(venueCodeValid);
        if (venueCodeValid) {
          dispatch(setValidGll(data.venue.glLimits));
          dispatch(setValidDrp(data.venue.drpLimits));
          dispatch(
            includeWaiverOfTransferRights(
              data.venue.additionalCoverage.find(
                (ac) => ac.type === "waiver-of-transfer-rights"
              )?.value[0] === true
            )
          );
          dispatch(setKnownVenue(data));
          dispatch(setIsFormPristine(false));
          if (data.places) {
            dispatch(getUtcOffset(data.places[0].placeId));
          } else {
            dispatch(getCoordsForUtc(data.venue.zip));
          }
          dispatch(
            shouldLookupTax(
              data.venue.state,
              data.venue.city,
              policyFormName,
              setHasRegionalTax,
              setTaxRegions,
              setIsValidCity,
              setHasLookedUpTax
            )
          );
        }
      })
      .catch((err) => {
        setVenueCodeChecked(true);
        setValidVenueCode(false);
        dispatch(setIsPristine(false));
        dispatch(errorResponse(err));
      });
  };
};

export const RESET_SPECIAL_TAX_FORM_FLAGS = "RESET_SPECIAL_TAX_FORM_FLAGS";
export const resetSpecialTaxFormFlags = () => {
  return { type: RESET_SPECIAL_TAX_FORM_FLAGS };
};

export const clearSpecialTaxForms = (formName) => {
  return (dispatch) => {
    dispatch(change(formName, "federalEntity", "no"));
    dispatch(change(formName, "kentuckyStateEntity", "no"));
    dispatch(resetSpecialTaxFormFlags());
  };
};

export const setKentuckyCity = (formName, city) => {
  return (dispatch) => dispatch(change(formName, "manualVenueCity", city));
};

// Request Facility search results from the server, and dispatch returned data
export const UPDATE_FACILITY_DATA = "UPDATE_FACILITY_DATA";
export const updateFacilityData = (data) => {
  return { type: UPDATE_FACILITY_DATA, payload: data };
};
export const getFacility = (id) => {
  return (dispatch) => {
    return new FacilityService(new API())
      .getFacility(id)
      .then((response) => dispatch(updateFacilityData(response.data)))
      .catch((err) => dispatch(errorResponse(err)));
  };
};
export const UPDATE_FACILITIES_TABLE = "UPDATE_FACILITIES_TABLE";
const updateFacilitiesTable = (data) => {
  return { type: UPDATE_FACILITIES_TABLE, payload: data };
};
export const UPDATE_FACILITIES_SEARCH_TERM = "UPDATE_FACILITIES_SEARCH_TERM";
const updateFacilitiesSearchTerm = (data) => {
  return { type: UPDATE_FACILITIES_SEARCH_TERM, payload: data };
};
export const SET_EDITING = "SET_EDITING";
export const setEditingAction = (data) => {
  return { type: SET_EDITING, payload: data };
};
export const getSearchFacilities = (searchInput, page, perPage) => {
  return (dispatch) => {
    dispatch(updateFacilitiesSearchTerm(searchInput));
    new FacilityService(new API())
      .getFacilitiesSearchResults(searchInput, page, perPage)
      .then((response) => {
        dispatch(updateFacilitiesTable(response.data));
        dispatch(setEditingAction(false));
      })
      .catch((err) => dispatch(errorResponse(err)));
  };
};

export const SET_VENUE_STATUS_MESSAGE = "SET_VENUE_STATUS_MESSAGE";
export const setVenueStatusMessage = (data) => {
  return { type: SET_VENUE_STATUS_MESSAGE, payload: data };
};

export const SHOW_VENUE_STATUS_ALERT = "SHOW_VENUE_STATUS_ALERT";
export const showVenueStatusAlert = (data) => {
  return { type: SHOW_VENUE_STATUS_ALERT, payload: data };
};

export const UPDATE_REASSIGN_VENUE_FACILITIES_TABLE =
  "UPDATE_REASSIGN_VENUE_FACILITIES_TABLE";
export const updateReassignVenueFacilitiesTable = (data) => {
  return { type: UPDATE_REASSIGN_VENUE_FACILITIES_TABLE, payload: data };
};

export const searchFacilitiesForVenue = (searchInput) => (dispatch) =>
  new FacilityService(new API())
    .getReassignVenueFacilities(searchInput)
    .then((response) =>
      dispatch(updateReassignVenueFacilitiesTable(response.data))
    )
    .catch((err) => dispatch(errorResponse(err)));

export const SET_REASSIGNED_VENUE_DATA = "SET_REASSIGNED_VENUE_DATA";
export const setReassignedVenueData = (data) => {
  return { type: SET_REASSIGNED_VENUE_DATA, payload: data };
};

export const RESET_REASSIGNED_VENUE_DATA = "RESET_REASSIGNED_VENUE_DATA";
export const resetReassignedVenueData = () => {
  return { type: RESET_REASSIGNED_VENUE_DATA };
};

export const reassignVenueFacility =
  (
    venue,
    facilityCode,
    refresh,
    setModalState,
    handleCloseReassign,
    handleShowError
  ) =>
  (dispatch) =>
    new VenueService(new API())
      .postReassignVenueFacility(venue, facilityCode)
      .then((response) => {
        if (response && response.status === 200) {
          refresh();
          setModalState(VENUE_REASSIGN_UPDATED);
          dispatch(setReassignedVenueData(response.data));
        }
      })
      .catch((err) => {
        handleCloseReassign();
        handleShowError();
        refresh();
        dispatch(errorResponse(err));
      });

export const SET_SELECTED_FACILITIES_IN_RESULTS =
  "SET_SELECTED_FACILITIES_IN_RESULTS";
export const setSelectedFacilityInResults = (data) => {
  return { type: SET_SELECTED_FACILITIES_IN_RESULTS, payload: data };
};

export const SHOW_FACILITY_STATUS_ALERT = "SHOW_FACILITY_STATUS_ALERT";
export const showFacilityStatusAlert = (data) => {
  return { type: SHOW_FACILITY_STATUS_ALERT, payload: data };
};

export const SET_NEW_FACILITY_NAME = "SET_NEW_FACILITY_NAME";
export const setNewFacilityStatusMessage = (data) => {
  return { type: SET_NEW_FACILITY_NAME, payload: data };
};

// Request Producer search results from the server, and dispatch returned data
export const UPDATE_PRODUCER_DATA = "UPDATE_PRODUCER_DATA";
export const updateProducerData = (data) => {
  return { type: UPDATE_PRODUCER_DATA, payload: data };
};
export const getProducer = (id) => {
  return (dispatch) => {
    return new ProducerService(new API())
      .getProducer(id)
      .then((response) => dispatch(updateProducerData(response.data)))
      .catch((err) => dispatch(errorResponse(err)));
  };
};
export const UPDATE_PRODUCERS_TABLE = "UPDATE_PRODUCERS_TABLE";
const updateProducersTable = (data) => {
  return { type: UPDATE_PRODUCERS_TABLE, payload: data };
};
export const UPDATE_PRODUCERS_SEARCH_TERM = "UPDATE_PRODUCERS_SEARCH_TERM";
const updateProducersSearchTerm = (data) => {
  return { type: UPDATE_PRODUCERS_SEARCH_TERM, payload: data };
};
export const getSearchProducers = (searchInput, page, perPage) => {
  return (dispatch) => {
    dispatch(updateProducersSearchTerm(searchInput));
    new ProducerService(new API())
      .getProducersSearchResults(searchInput, page, perPage)
      .then((response) => {
        dispatch(updateProducersTable(response.data));
      })
      .catch((err) => dispatch(errorResponse(err)));
  };
};

export const SHOW_NEW_PRODUCER_ALERT = "SHOW_NEW_PRODUCER_ALERT";
export const showNewProducerAlert = (data) => {
  return { type: SHOW_NEW_PRODUCER_ALERT, payload: data };
};

export const SET_NEW_PRODUCER_STATUS_MESSAGE =
  "SET_NEW_PRODUCER_STATUS_MESSAGE";
export const setNewProducerStatusMessage = (data) => {
  return { type: SET_NEW_PRODUCER_STATUS_MESSAGE, payload: data };
};

export const SET_VALID_PRODUCER_CODE = "SET_VALID_PRODUCER_CODE";
export const setValidProducerCode = (data) => {
  return { type: SET_VALID_PRODUCER_CODE, payload: data };
};

export const SET_HAS_CHECKED_PRODUCER_CODE = "SET_HAS_CHECKED_PRODUCER_CODE";
export const setHasCheckedProducerCode = (data) => {
  return { type: SET_HAS_CHECKED_PRODUCER_CODE, payload: data };
};

export const checkAndSetValidProducerCode = (commissionId, subProducerCode) => {
  return (dispatch) =>
    new ProducerService(new API())
      .getIsValidProducerCode(commissionId, subProducerCode)
      .then((response) => {
        dispatch(setHasCheckedProducerCode(true));
        dispatch(setValidProducerCode(response.data.valid));
      })
      .catch((err) => {
        dispatch(setHasCheckedProducerCode(false));
        dispatch(errorResponse(err));
      });
};

export const SET_DEFAULT_PRODUCER = "SET_DEFAULT_PRODUCER";
export const setDefaultProducer = (data) => {
  return { type: SET_DEFAULT_PRODUCER, payload: data };
};

export const getDefaultProducer = () => {
  return (dispatch) =>
    new ProducerService(new API())
      .getIsValidCommissionId(DEFAULT_PRODUCER_CODE)
      .then((response) => {
        dispatch(setDefaultProducer(response.data.producer));
      })
      .catch((err) => {
        dispatch(errorResponse(err));
      });
};

// Request Association search results from the server, and dispatch returned data
export const UPDATE_ASSOCIATION_DATA = "UPDATE_ASSOCIATION_DATA";
export const updateAssociationData = (data) => {
  return { type: UPDATE_ASSOCIATION_DATA, payload: data };
};
export const getAssociation = (id) => {
  return (dispatch) => {
    return new AssociationService(new API())
      .getAssociation(id)
      .then((response) => dispatch(updateAssociationData(response.data)))
      .catch((err) => dispatch(errorResponse(err)));
  };
};
export const UPDATE_ASSOCIATIONS_TABLE = "UPDATE_ASSOCIATIONS_TABLE";
const updateAssociationsTable = (data) => {
  return { type: UPDATE_ASSOCIATIONS_TABLE, payload: data };
};
export const UPDATE_ASSOCIATIONS_SEARCH_TERM =
  "UPDATE_ASSOCIATIONS_SEARCH_TERM";
const updateAssociationsSearchTerm = (data) => {
  return { type: UPDATE_ASSOCIATIONS_SEARCH_TERM, payload: data };
};
export const getSearchAssociations = (searchInput, page, perPage) => {
  return (dispatch) => {
    dispatch(updateAssociationsSearchTerm(searchInput));
    new AssociationService(new API())
      .getAssociationsSearchResults(searchInput, page, perPage)
      .then((response) => {
        dispatch(updateAssociationsTable(response.data));
      })
      .catch((err) => dispatch(errorResponse(err)));
  };
};

export const SHOW_NEW_ASSOCIATION_ALERT = "SHOW_NEW_ASSOCIATION_ALERT";
export const showNewAssociationAlert = (data) => {
  return { type: SHOW_NEW_ASSOCIATION_ALERT, payload: data };
};

export const SET_NEW_ASSOCIATION_STATUS_MESSAGE =
  "SET_NEW_ASSOCIATION_STATUS_MESSAGE";
export const setNewAssociationStatusMessage = (data) => {
  return { type: SET_NEW_ASSOCIATION_STATUS_MESSAGE, payload: data };
};

export const checkIsValidCode = (
  code,
  masterAgency,
  setHasCheckedCode,
  setValidCode
) => {
  return (dispatch) =>
    new AssociationService(new API())
      .getIsValidCode(code, masterAgency)
      .then((response) => {
        setHasCheckedCode(true);
        setValidCode(!response.data.exists);
      })
      .catch((err) => {
        setHasCheckedCode(false);
        dispatch(errorResponse(err));
      });
};

export const ADD_FACILITY_CONTACT = "ADD_FACILITY_CONTACT";
export const addFacilityContact = () => {
  return { type: ADD_FACILITY_CONTACT };
};

export const checkIsValidFacilityCode = (
  id,
  setHasCheckedFacilityCode,
  setValidFacilityCode
) => {
  setHasCheckedFacilityCode(false);
  return (dispatch) =>
    new FacilityService(new API())
      .getIsValidFacilityCode(id)
      .then((response) => {
        setHasCheckedFacilityCode(true);
        setValidFacilityCode(!response.data.exists);
      })
      .catch((err) => {
        setHasCheckedFacilityCode(false);
        dispatch(errorResponse(err));
      });
};

export const SET_PRODUCER_SEARCH_RESULTS = "SET_PRODUCER_SEARCH_RESULTS";
export const setProducerSearchResults = (data) => {
  return { type: SET_PRODUCER_SEARCH_RESULTS, payload: data };
};

export const searchProducersForFacility = (searchInput) => {
  return (dispatch) =>
    new FacilityService(new API())
      .getProducersForFacility(searchInput)
      .then((response) => dispatch(setProducerSearchResults(response.data)))
      .catch((err) => {
        dispatch(errorResponse(err));
      });
};

export const SET_GENERATED_FACILITY_CODE = "SET_GENERATED_FACILITY_CODE";
export const setGeneratedFacilityCode = (data) => {
  return { type: SET_GENERATED_FACILITY_CODE, payload: data };
};

export const getGeneratedFacilityCodeAction = (
  setHasCheckedFacilityCode,
  setValidFacilityCode
) => {
  return (dispatch) =>
    new FacilityService(new API())
      .getGeneratedFacilityCode()
      .then((response) => {
        dispatch(setGeneratedFacilityCode(response.data.generatedCode));
        setHasCheckedFacilityCode(true);
        setValidFacilityCode(true);
      })
      .catch((err) => {
        setHasCheckedFacilityCode(false);
        setValidFacilityCode(false);
        dispatch(errorResponse(err));
      });
};

export const SET_SELECTED_PRODUCERS_IN_RESULTS =
  "SET_SELECTED_PRODUCERS_IN_RESULTS";
export const setSelectedProducerInResults = (data) => {
  return { type: SET_SELECTED_PRODUCERS_IN_RESULTS, payload: data };
};

export const SET_DEFAULT_ADD_FACILITY_COMMISSION_RATE =
  "SET_DEFAULT_ADD_FACILITY_COMMISSION_RATE";
export const setDefaultAddFacilityCommissionRate = (data) => {
  return { type: SET_DEFAULT_ADD_FACILITY_COMMISSION_RATE, payload: data };
};

export const UPDATE_VENUES_SEARCH_TERM = "UPDATE_VENUES_SEARCH_TERM";
const updateVenuesSearchTerm = (data) => {
  return { type: UPDATE_VENUES_SEARCH_TERM, payload: data };
};

export const UPDATE_VENUES_TABLE = "UPDATE_VENUES_TABLE";
const updateVenuesTable = (data) => {
  return { type: UPDATE_VENUES_TABLE, payload: data };
};

// Request Venue search results from the server, and dispatch returned data
export const UPDATE_VENUE_DATA = "UPDATE_VENUE_DATA";
export const updateVenueData = (data) => {
  return { type: UPDATE_VENUE_DATA, payload: data };
};

export const UPDATE_CUSTOM_CONTACTS = "UPDATE_CUSTOM_CONTACTS";
export const updateCustomContacts = (data) => {
  return { type: UPDATE_CUSTOM_CONTACTS, payload: data };
};

export const UPDATE_INITIAL_CUSTOM_CONTACTS = "UPDATE_INITIAL_CUSTOM_CONTACTS";
export const updateInitialCustomContacts = (data) => {
  return { type: UPDATE_INITIAL_CUSTOM_CONTACTS, payload: data };
};

export const getVenue = (facilityId, venueId) => {
  return (dispatch) => {
    return new VenueService(new API())
      .getVenue(facilityId, venueId)
      .then((response) => dispatch(updateVenueData(response.data)))
      .catch((err) => dispatch(errorResponse(err)));
  };
};

export const getSearchAllVenues = (searchInput = "", page, perPage) => {
  return (dispatch) => {
    dispatch(updateVenuesSearchTerm(searchInput));
    new VenueService(new API())
      .getAllVenuesSearchResults(searchInput, page, perPage)
      .then((response) => {
        dispatch(updateVenuesTable(response.data));
      })
      .catch((err) => dispatch(errorResponse(err)));
  };
};

export const getSearchVenues = (
  searchInput = "",
  page,
  perPage,
  facilityId
) => {
  return (dispatch) => {
    dispatch(updateVenuesSearchTerm(searchInput, facilityId));
    new VenueService(new API())
      .getVenuesSearchResults(searchInput, page, perPage, facilityId)
      .then((response) => {
        dispatch(updateVenuesTable(response.data));
        dispatch(setEditingAction(false));
      })
      .catch((err) => dispatch(errorResponse(err)));
  };
};

export const getVenueForPlaceId = (id) =>
  new VenueService(new API())
    .getPlaceExists(id)
    .then((response) => {
      return response.data.venue;
    })
    .catch((err) => {
      return err;
    });

export const UPDATE_CONTACTS_TABLE = "UPDATE_CONTACTS_TABLE";
const updateContactsTable = (data) => {
  return { type: UPDATE_CONTACTS_TABLE, payload: data };
};

export const UPDATE_CONTACTS_SEARCH_TERM = "UPDATE_CONTACTS_SEARCH_TERM";
const updateContactsSearchTerm = (data) => {
  return { type: UPDATE_CONTACTS_SEARCH_TERM, payload: data };
};

export const getSearchContacts = (
  searchInput = "",
  contactType,
  page,
  perPage
) => {
  return (dispatch) => {
    dispatch(updateContactsSearchTerm(searchInput));
    new ContactsService(new API())
      .getContacts(searchInput, contactType, page, perPage)
      .then((response) => {
        dispatch(
          updateContactsTable({
            contactType,
            contacts: response.data,
          })
        );
      })
      .catch((err) => dispatch(errorResponse(err)));
  };
};

export const TOGGLE_ALL_CONTACT_CHECKBOXES = "TOGGLE_ALL_CONTACT_CHECKBOXES";
export const toggleAllContactCheckboxes = (data) => {
  return { type: TOGGLE_ALL_CONTACT_CHECKBOXES, payload: data };
};

export const CLEAR_VENUE_ALERT = "CLEAR_VENUE_ALERT";
export const clearVenueAlert = () => {
  return { type: CLEAR_VENUE_ALERT };
};

export const CLEAR_VENUES_TABLE = "CLEAR_VENUES_TABLE";
export const clearVenuesTable = () => {
  return { type: CLEAR_VENUES_TABLE };
};

export const CLEAR_FACILITY_DATA = "CLEAR_FACILITY_DATA";
export const clearFacilityData = () => {
  return { type: CLEAR_FACILITY_DATA };
};

export const CLEAR_PRODUCER_DATA = "CLEAR_PRODUCER_DATA";
export const clearProducerData = () => {
  return { type: CLEAR_PRODUCER_DATA };
};

export const CLEAR_ASSOCIATION_DATA = "CLEAR_ASSOCIATION_DATA";
export const clearAssociationData = () => {
  return { type: CLEAR_ASSOCIATION_DATA };
};

export const CLEAR_POLICY_DATA = "CLEAR_POLICY_DATA";
export const clearPolicyData = () => {
  return { type: CLEAR_POLICY_DATA };
};

export const CLEAR_VENUE_DATA = "CLEAR_VENUE_DATA";
export const clearVenueData = () => {
  return { type: CLEAR_VENUE_DATA };
};

export const CLEAR_CONTACTS_TABLE = "CLEAR_CONTACTS_TABLE";
export const clearContactsTable = () => {
  return { type: CLEAR_CONTACTS_TABLE };
};

export const UPDATE_CONTACTS_ALERT = "UPDATE_CONTACTS_ALERT";
export const updateShowContactsAlert = (data) => {
  return { type: UPDATE_CONTACTS_ALERT, payload: data };
};

export const UPDATE_CONTACTS_ERROR = "UPDATE_CONTACTS_ERROR";
export const updateShowContactsError = (data) => {
  return { type: UPDATE_CONTACTS_ERROR, payload: data };
};

export const UPDATE_CONTACTS_ERROR_MESSAGE = "UPDATE_CONTACTS_ERROR_MESSAGE";
export const updateContactsErrorMessage = (data) => {
  return { type: UPDATE_CONTACTS_ERROR_MESSAGE, payload: data };
};

export const UPDATE_CONTACTS_MODAL_REMOVE = "UPDATE_CONTACTS_MODAL_REMOVE";
export const updateShowModalRemove = (data) => {
  return { type: UPDATE_CONTACTS_MODAL_REMOVE, payload: data };
};

export const UPDATE_CONTACTS_MODAL_OVERWRITE =
  "UPDATE_CONTACTS_MODAL_OVERWRITE";
export const updateShowModalOverwrite = (data) => {
  return { type: UPDATE_CONTACTS_MODAL_OVERWRITE, payload: data };
};

export const resetOverwriteFields = () => {
  return (dispatch) => {
    dispatch(change(allContactsForm, "overwriteName", ""));
    dispatch(change(allContactsForm, "overwriteRole", ""));
    dispatch(change(allContactsForm, "overwriteEmail", ""));
    dispatch(change(allContactsForm, "overwriteCopyOnEmails", ""));
  };
};

export const allContactsToggleCheckboxes = (data, count) => {
  return (dispatch) => {
    for (let i = 0; i < count; i += 1) {
      dispatch(change(allContactsForm, `selectedContacts[${i}]`, data));
    }
  };
};

export const deleteContacts = (contactType, contactsToDelete) => {
  return (dispatch, getState) => {
    const { contacts } = getState();
    new ContactsService(new API())
      .deleteContacts({ contactType, contactsToDelete })
      .then((response) => {
        if (response) {
          dispatch(
            getSearchContacts(
              contacts.searchTerm,
              contactType,
              contacts.contactsPagination.page,
              contacts.contactsPagination.perPage
            )
          );
          dispatch(updateShowModalRemove(false));
          dispatch(reset("allContactsForm"));
          dispatch(updateContactsErrorMessage(""));
          dispatch(updateShowContactsError(false));
          dispatch(updateShowContactsAlert(true));
        }
      })
      .catch((err) => {
        dispatch(errorResponse(err));
        const { message } = err.response.data;
        dispatch(updateContactsErrorMessage(message));
        dispatch(updateShowContactsAlert(true));
      });
  };
};

export const updateContacts = (
  contactType,
  contactsToUpdate,
  newContactData
) => {
  return (dispatch, getState) => {
    const { contacts } = getState();
    new ContactsService(new API())
      .updateContacts({ contactType, contactsToUpdate, newContactData })
      .then((response) => {
        if (response) {
          dispatch(
            getSearchContacts(
              contacts.searchTerm,
              contactType,
              contacts.contactsPagination.page,
              contacts.contactsPagination.perPage
            )
          );
          dispatch(updateShowModalOverwrite(false));
          dispatch(reset("allContactsForm"));
          dispatch(updateContactsErrorMessage(""));
          dispatch(updateShowContactsError(false));
          dispatch(updateShowContactsAlert(true));
        }
      })
      .catch((err) => {
        dispatch(errorResponse(err));

        const { message } = err.response.data;
        dispatch(updateContactsErrorMessage(message));
        dispatch(updateShowModalOverwrite(false));
        dispatch(resetOverwriteFields());
        dispatch(updateShowContactsError(true));
      });
  };
};

export const UPDATE_SELECTED_CONTACTS = "UPDATE_SELECTED_CONTACTS";
export const updateSelectedContacts = (data) => {
  return { type: UPDATE_SELECTED_CONTACTS, payload: data };
};

export const UPDATE_CONTACT_TYPE = "UPDATE_CONTACT_TYPE";
export const updateContactType = (data) => {
  return { type: UPDATE_CONTACT_TYPE, payload: data };
};

export const BATCH_UPDATE_FORM_FIELDS = "BATCH_UPDATE_FORM_FIELDS";
export const batchUpdateFormFields = (formName, fields, value = "") => {
  return (dispatch) => {
    fields.forEach((field) => {
      dispatch(change(formName, field, value));
    });
  };
};

export const CLEAR_FIELD_ERRORS = "CLEAR_FIELD_ERRORS";
export const clearFieldErrors = (formName, fields) => {
  return (dispatch) => {
    fields.forEach((field) => {
      dispatch(untouch(formName, [field]));
    });
  };
};

export const SET_USERS_LOADING = "SET_USERS_LOADING";
export const setUsersLoading = (isLoading) => ({
  type: SET_USERS_LOADING,
  payload: { isLoading },
});

export const UPDATE_USERS_TABLE = "UPDATE_USERS_TABLE";
export const updateUsersTable = (data) => {
  return { type: UPDATE_USERS_TABLE, payload: data };
};

export const RESET_USERS = "RESET_USERS";
export const resetUsers = () => ({ type: RESET_USERS });

export const USER_SAVE_SUCCESS = "USER_SAVE_SUCCESS";
export const userSaveSuccess = (user) => ({
  type: USER_SAVE_SUCCESS,
  payload: user,
});

export const SET_USER_ROLE_SELECTION_VISIBLE =
  "SET_USER_ROLE_SELECTION_VISIBLE";
export const setUserRoleSelectionVisible = (visible) => ({
  type: SET_USER_ROLE_SELECTION_VISIBLE,
  payload: visible,
});

export const SET_DELETE_USER_ERROR = "SET_DELETE_USER_ERROR";
export const setDeleteUserError = (error) => ({
  type: SET_DELETE_USER_ERROR,
  payload: error,
});

export const TOGGLE_POLICIES_EMPTY_VENUE_FILTER =
  "TOGGLE_POLICIES_EMPTY_VENUE_FILTER";
export const togglePoliciesUnknownVenuesFilter = () => ({
  type: TOGGLE_POLICIES_EMPTY_VENUE_FILTER,
});

export const DISABLE_POLICIES_EMPTY_VENUE_FILTER =
  "DISABLE_POLICIES_EMPTY_VENUE_FILTER";
export const disablePoliciesUnknownVenuesFilter = () => ({
  type: DISABLE_POLICIES_EMPTY_VENUE_FILTER,
});

export const filterPoliciesWithUnknownVenues = () => (dispatch, getState) => {
  const page = 1;
  dispatch(togglePoliciesUnknownVenuesFilter());
  dispatch(setPage(page));
  const { policies } = getState();
  dispatch(
    getSearchPolicies(
      policies.searchTerm,
      page,
      policies.policyPagination.perPage
    )
  );
};
