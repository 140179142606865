import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";

const errorDisplay = ({ meta: { touched, error } }) => (
  <>
    <div className={touched && error ? "label form-error__label" : "label"}>
      Who is the renter of the venue?
    </div>
    {touched && error ? <div className="form-error">{error}</div> : false}
  </>
);
errorDisplay.propTypes = {
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
};

const RenterField = ({ name, validate }) => (
  <div className="form-group">
    <Field name={name} component={errorDisplay} />

    <div className="custom-control custom-radio custom-control-inline">
      <Field
        type="radio"
        id={`${name}Individual`}
        name={name}
        validate={validate}
        className="custom-control-input"
        component="input"
        value="Person"
      />
      <label
        className="custom-control-label label"
        htmlFor={`${name}Individual`}
      >
        an individual
      </label>
    </div>
    <div className="custom-control custom-radio custom-control-inline">
      <Field
        type="radio"
        id={`${name}Company`}
        name={name}
        validate={validate}
        className="custom-control-input"
        component="input"
        value="Company"
      />
      <label className="custom-control-label label" htmlFor={`${name}Company`}>
        a company/organization
      </label>
    </div>
  </div>
);
RenterField.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.arrayOf(PropTypes.func),
};
RenterField.defaultProps = {
  validate: [],
};

export default RenterField;
