import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { reduxForm, Field, SubmissionError, reset } from "redux-form";
import { ConnectedRouter, TextInput } from "@jauntin/react-ui";
import * as qs from "query-string";
import { twoFactorFormName } from "../../constants";
import { required } from "../../Helpers/validators";
import AuthService from "@gbli-events/common/src/Helpers/AuthService";
import API from "../../Helpers/API";
import { getUrl, LOGIN_PAGE, POLICIES_PAGE } from "../../Helpers/URLParser";
import { FormPropTypes } from "@gbli-events/common/src/Constants/ReduxFormPropTypes";
import { updateLoginStatus } from "../../Actions/actions";
import Logo from "@gbli-events/common/src/Assets/Images/gbli-logo-colour-logo.svg";
import { Button } from "@jauntin/react-ui";
import { normalizers } from "@jauntin/utilities";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { adminUserPropType } from "../../Helpers/UserModel";
import { pluralize } from "@gbli-events/common/src/Helpers/StringHelper";

const normalize6Digits = normalizers.normalizeDigits(6);
const nowInSeconds = () => Math.floor(Date.now() / 1000);

const CountdownTimer = ({ resendVerification }) => {
  const history = useHistory();
  const resendThrottle = 30;
  const resendThrottleGrace = 1; // Delay before reenabling resend to avoid borderline cases
  const tokenLifetime = 300;
  const tokenLifetimeGrace = 5; // Preempt token lifetime to avoid borderline cases
  const [now, setNow] = useState(nowInSeconds());
  const [tokenCreationTime, setTokenCreationTime] = useState(now);
  const [showButton, setShowButton] = useState(false);
  const [error, setError] = useState(null);
  const timeSinceTokenCreation = useMemo(
    () => tokenCreationTime - now,
    [tokenCreationTime, now]
  );
  const timeToResend = useMemo(() => {
    const time = timeSinceTokenCreation + resendThrottle + resendThrottleGrace;
    return Math.max(0, Math.min(resendThrottle, time));
  }, [timeSinceTokenCreation]);
  const tokenHasExpired = useMemo(
    () => timeSinceTokenCreation + tokenLifetime - tokenLifetimeGrace <= 0,
    [timeSinceTokenCreation]
  );

  useEffect(() => {
    // Update the current time `now` on an interval < 1s
    const interval = setInterval(() => setNow(nowInSeconds()), 200);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (timeToResend <= 0) {
      setShowButton(true);
    }
  }, [timeToResend]);
  useEffect(() => {
    if (tokenHasExpired) {
      const { returnUrl } = qs.parse(window.location.search);
      history.replace(
        getUrl(LOGIN_PAGE) + (returnUrl ? `?returnUrl=${returnUrl}` : "")
      );
    }
  }, [history, tokenHasExpired]);

  return (
    <div>
      Did not receive an email?{" "}
      {showButton ? (
        <Button
          className="btn-link"
          type="button"
          text="Resend verification code"
          onClick={async () => {
            if (await resendVerification(setError)) {
              setShowButton(false);
              setTokenCreationTime(now);
            }
          }}
        />
      ) : (
        <span>
          Resend verification code in {timeToResend}{" "}
          {pluralize("second", timeToResend)}
        </span>
      )}
      {error && (
        <div className="form-error my-1 ml-2 text-left">
          Something went wrong, please try again
        </div>
      )}
    </div>
  );
};

CountdownTimer.propTypes = {
  resendVerification: PropTypes.func.isRequired,
};

let TwoFactorForm = ({
  currentUser,
  valid,
  handleSubmit,
  resendVerification,
  submitting,
  error,
}) => {
  const history = useHistory();

  useEffect(() => {
    if (!currentUser) {
      return history.replace(getUrl(LOGIN_PAGE));
    }
  }, [history, currentUser]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="container text-center pt-5 mt-5">
        <img
          src={Logo}
          alt="Logo"
          width="188"
          height="198"
          className="img-fluid mx-auto"
        />
        <div className="col-sm-6 col-lg-4 mx-auto pt-5">
          <div>
            <Field
              label="Verification code"
              component={TextInput}
              validate={[required]}
              normalize={normalize6Digits}
              name="verificationCode"
              placeholder="XXXXXX"
              inputMode="numeric"
            />
          </div>
          {error && (
            <div className="form-error my-1 ml-2 text-left">{error}</div>
          )}
          <div>
            <Button
              disabled={!valid || submitting || !currentUser}
              text="Verify"
              type="submit"
              className="btn-primary w-100 my-3 py-2"
            />
            <CountdownTimer resendVerification={resendVerification} />
          </div>
        </div>
      </div>
    </form>
  );
};

TwoFactorForm = reduxForm({
  form: twoFactorFormName,
  shouldError: ({ initialRender, nextProps }) => {
    if (
      !initialRender &&
      nextProps.error &&
      !nextProps?.values?.verificationCode
    ) {
      return false;
    }
    return true;
  },
})(TwoFactorForm);

TwoFactorForm.propTypes = {
  ...FormPropTypes,
  currentUser: adminUserPropType,
  setShowLockout: PropTypes.func,
  resendVerification: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (values) =>
    dispatch(async (_, getState) => {
      const state = getState();
      try {
        await new AuthService(new API()).verifyTwoFactor({
          ...values,
          credentials: {
            email: state.app.currentUser.email,
          },
        });
        dispatch(updateLoginStatus(true));
        const { returnUrl } = qs.parse(window.location.search);
        return dispatch(
          ConnectedRouter.push(returnUrl || getUrl(POLICIES_PAGE))
        );
      } catch (e) {
        let message =
          "The verification code is invalid. Please request a new code and try again.";
        dispatch(updateLoginStatus(false));
        dispatch(reset(twoFactorFormName));
        throw new SubmissionError({
          _error: message,
        });
      }
    }),
  resendVerification: (setError) =>
    dispatch(async (_, getState) => {
      const state = getState();
      try {
        await new AuthService(new API()).resendTwoFactor({
          credentials: {
            email: state.app.currentUser.email,
          },
        });
        setError(null);
        return true;
      } catch (e) {
        setError(e);
        return false;
      }
    }),
});

const mapStateToProps = (state) => ({
  currentUser: state.app.currentUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorForm);
