import styled from "styled-components";
import { EmailInput } from "@jauntin/react-ui";

const StyledEmailField = styled(EmailInput)`
  input {
    ${({ theme }) => theme.components.Input?.customCss}
  }
`;

export default StyledEmailField;
