import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getPolicyId } from "../../Helpers/URLParser";
import { getPolicy, clearPolicyData } from "../../Actions/actions";
import { coveragePropType, emptyCoverage } from "../../Helpers/CoverageModel";

class LoadPolicy extends Component {
  componentDidMount() {
    const { id, loadPolicy } = this.props;
    loadPolicy(id);
  }

  componentWillUnmount() {
    const { clearPolicy } = this.props;
    clearPolicy();
  }

  render() {
    const { children, coverage } = this.props;
    return coverage.id === emptyCoverage.id ? <></> : <>{children}</>;
  }
}

LoadPolicy.propTypes = {
  children: PropTypes.node,
  id: PropTypes.number.isRequired,
  coverage: coveragePropType.isRequired,
  loadPolicy: PropTypes.func.isRequired,
  clearPolicy: PropTypes.func.isRequired,
};

LoadPolicy.defaultProps = {
  children: <></>,
};

const mapStateToProps = (state) => ({
  // state.router.pathname does not get updated quick enough on change for props to map
  // use window.location.pathname instead
  id: getPolicyId(window.location.pathname),
  coverage: state.policies.currentCoverage,
});

const mapDispatchToProps = (dispatch) => ({
  loadPolicy: (id) => dispatch(getPolicy(id)),
  clearPolicy: () => dispatch(clearPolicyData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadPolicy);
