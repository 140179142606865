export const companyTypeOptionsDict = {
  corporation: { value: "Corporation", label: "Corporation" },
  individual: {
    value: "Individual/Sole Proprietor",
    label: "Individual/Sole Proprietor",
  },
  jointVenture: { value: "Joint Venture", label: "Joint Venture" },
  llc: {
    value: "LLC - Limited Liability Corporation",
    label: "LLC - Limited Liability Corporation",
  },
  nonProfit: { value: "Not For Profit Org", label: "Not For Profit Org" },
  partnership: { value: "Partnership", label: "Partnership" },
  subchapter: {
    value: 'Subchapter "S" Corporation',
    label: 'Subchapter "S" Corporation',
  },
  trust: { value: "Trust", label: "Trust" },
  other: { value: "Other", label: "Other" },
};

export const companyTypeOptions = Object.values(companyTypeOptionsDict);
