import PropTypes from "prop-types";

const notePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  orderNumber: PropTypes.string,
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
});

export default notePropType;
