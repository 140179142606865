import React from "react";
import PropTypes from "prop-types";
import { Prompt } from "react-router-dom";

const RouteLeavingEditGuard = ({ when, path, showDiscard, setPath }) => (
  <Prompt
    when={when}
    message={(nextLocation, action) => {
      if (action === "PUSH" && path) {
        return true;
      }

      showDiscard();
      setPath(nextLocation.pathname);

      return false;
    }}
  />
);
RouteLeavingEditGuard.propTypes = {
  when: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  showDiscard: PropTypes.func.isRequired,
  setPath: PropTypes.func.isRequired,
};

export default RouteLeavingEditGuard;
