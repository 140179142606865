export const ROUTE_GUARD_BLOCKED = "ROUTE_GUARD_BLOCKED";
export const routeGuardBlocked = (path) => ({
  type: ROUTE_GUARD_BLOCKED,
  payload: path,
});

export const PAGE_NOT_FOUND = "PAGE_NOT_FOUND";
export const pageNotFound = () => ({
  type: PAGE_NOT_FOUND,
});
