class StateTaxService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  // Determine whether the selected state is subject to region specific taxes (boolean response)
  confirmRegionalTax(state = "") {
    return this.api.get({
      location: "/taxLookup/shouldLookupTax",
      params: {
        state,
      },
    });
  }

  // Get the tax rates specific to the city/region if above returns 'true'
  getTaxRegions(state = "", searchString = "") {
    return this.api.get({
      location: "/taxLookup/lookupTaxLocations",
      params: {
        state,
        searchString,
      },
    });
  }
}

export default StateTaxService;
