import React from "react";
import PropTypes from "prop-types";
import {
  FieldInputProps,
  FieldMetaProps,
} from "@gbli-events/common/src/Constants/ReduxFormPropTypes";
import SelectList from "../SelectList";
import StyledSelectListField from "./StyledSelectListField";
import { GBLI_THEME } from "../../../Constants/themes/gbliTheme";
import themePropType from "../../../Models/Theme";

const SelectListField = ({
  input,
  meta,
  label,
  options,
  placeholder,
  disabled,
  searchable,
  className,
  required,
  inputMode,
  pattern,
  resetValue,
  theme,
  components,
}) => (
  <StyledSelectListField
    className={`select-list-field form-group ${className} ${
      required ? "required" : ""
    }`}
  >
    {label && (
      <label
        htmlFor={input.name}
        className={
          meta.touched && meta.error ? "label form-error__label" : "label"
        }
      >
        {label}
      </label>
    )}
    {meta.touched && meta.error && (
      <div className="form-error">{meta.error}</div>
    )}
    <SelectList
      placeholder={placeholder}
      options={options}
      input={input}
      meta={meta}
      inputMode={inputMode}
      pattern={pattern}
      searchable={searchable}
      disabled={disabled}
      resetValue={resetValue}
      theme={theme}
      components={components}
    />
  </StyledSelectListField>
);
SelectListField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
  searchable: PropTypes.bool,
  required: PropTypes.bool,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })
  ),
  inputMode: PropTypes.string,
  pattern: PropTypes.string,
  resetValue: PropTypes.any,
  theme: themePropType,
  components: PropTypes.objectOf(PropTypes.node),
};

SelectListField.defaultProps = {
  className: "",
  placeholder: "",
  theme: GBLI_THEME,
};

export default SelectListField;
