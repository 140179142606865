// https://stackoverflow.com/a/34210131/6252145
const suffixes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

const getReadableFileSize = (bytes) => {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    (!bytes && "0 Bytes") || `${(bytes / 1024 ** i).toFixed(2)} ${suffixes[i]}`
  );
};

export default getReadableFileSize;
