import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import ProducersDetailsHeader from "./ProducersDetailsHeader";
import {
  getUrl,
  PRODUCER_PAGE,
  PRODUCER_NOTES_PAGE,
} from "../../Helpers/URLParser";

const ProducersMenu = ({ id }) => {
  return (
    <div className="subsection-menu">
      <ProducersDetailsHeader />
      <div className="subsection-menu__items">
        <NavLink
          to={getUrl(PRODUCER_PAGE, id)}
          className="subsection-menu__item"
          activeClassName="active"
        >
          Producer Overview
        </NavLink>
        <NavLink
          to={getUrl(PRODUCER_NOTES_PAGE, id)}
          className="subsection-menu__item"
          activeClassName="active"
        >
          Notes
        </NavLink>
      </div>
    </div>
  );
};

ProducersMenu.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ProducersMenu;
