import PropTypes from "prop-types";

const paletteColorPropType = PropTypes.shape({
  light: PropTypes.string,
  main: PropTypes.string,
  dark: PropTypes.string,
  contrastText: PropTypes.string,
});

const themePropType = PropTypes.shape({
  themeSlug: PropTypes.string,
  title: PropTypes.string.isRequired,
  favicons: PropTypes.arrayOf(
    PropTypes.shape({
      rel: PropTypes.string,
      type: PropTypes.string,
      sizes: PropTypes.string,
      href: PropTypes.string,
    })
  ),
  breakpoints: PropTypes.shape({
    xxs: PropTypes.string,
    xs: PropTypes.string,
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
  }),
  palette: PropTypes.shape({
    primary: paletteColorPropType,
    secondary: paletteColorPropType,
    error: paletteColorPropType,
  }),
  typography: PropTypes.shape({
    fonts: PropTypes.shape({
      primary: PropTypes.string,
      secondary: PropTypes.string,
    }),
    h1: PropTypes.string,
    h2: PropTypes.string,
    h3: PropTypes.string,
    h4: PropTypes.string,
    h5: PropTypes.string,
    h6: PropTypes.string,
  }),
  globalStyles: PropTypes.string,
  components: PropTypes.shape({
    Alert: PropTypes.shape({
      customCss: PropTypes.string,
    }),
    Button: PropTypes.shape({
      customCss: PropTypes.string,
    }),
    ButtonToggle: PropTypes.shape({
      backgroundColor: PropTypes.string,
      backgroundColorActive: PropTypes.string,
      borderColor: PropTypes.string,
      borderColorActive: PropTypes.string,
      color: PropTypes.string,
      colorActive: PropTypes.string,
      borderRadius: PropTypes.string,
    }),
    CheckboxField: PropTypes.shape({
      activeColor: PropTypes.string,
      activeSecondaryColor: PropTypes.string,
      customCss: PropTypes.string,
    }),
    DateRangePickerField: PropTypes.shape({
      dateSelectedColor: PropTypes.string,
    }),
    EventCard: PropTypes.shape({
      outlineColor: PropTypes.string,
      outlineColorActive: PropTypes.string,
      borderRadius: PropTypes.string,
      backgroundColor: PropTypes.string,
      backgroundColorActive: PropTypes.string,
      dividerColor: PropTypes.string,
      color: PropTypes.string,
      customCss: PropTypes.string,
    }),
    Footer: PropTypes.shape({
      navItems: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          url: PropTypes.string,
          target: PropTypes.string,
        })
      ),
      customCss: PropTypes.string,
    }),
    FooterLink: PropTypes.shape({
      customCss: PropTypes.string,
    }),
    Header: PropTypes.shape({
      logoUrl: PropTypes.string,
      navItems: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          url: PropTypes.string,
          target: PropTypes.string,
        })
      ),
      customCss: PropTypes.string,
    }),
    HeaderLogo: PropTypes.shape({
      href: PropTypes.string,
      customCss: PropTypes.string,
    }),
    HeaderLink: PropTypes.shape({
      customCss: PropTypes.string,
    }),
    Input: PropTypes.shape({
      customCss: PropTypes.string,
    }),
    InputGroup: PropTypes.shape({
      customCss: PropTypes.string,
    }),
    PriceSummaryFooter: PropTypes.shape({
      customCss: PropTypes.string,
    }),
    QuoteSummaryFloater: PropTypes.shape({
      customCss: PropTypes.string,
    }),
    RadioFieldGroup: PropTypes.shape({
      checkRadius: PropTypes.string,
      borderRadius: PropTypes.string,
      radioColor: PropTypes.string,
      radioColorActive: PropTypes.string,
      radioColorSecondaryActive: PropTypes.string,
    }),
    Select: PropTypes.shape({
      borderColor: PropTypes.string,
      borderColorActive: PropTypes.string,
      indicatorColor: PropTypes.string,
      borderRadius: PropTypes.string,
      fontFamily: PropTypes.string,
      customCss: PropTypes.string,
    }),
    SelectListField: PropTypes.shape({
      customCss: PropTypes.string,
    }),
    TextField: PropTypes.shape({
      customCss: PropTypes.string,
    }),
  }),
});

export default themePropType;
