import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import SearchVenuesContainer from "../SearchVenuesContainer/SearchVenuesContainer";
import { emptyFacility, facilityPropType } from "../../Helpers/FacilityModel";
import { reassignVenueFormName } from "../../constants";

const Venues = ({ facility }) => {
  return (
    <SearchVenuesContainer
      facilityId={facility.id}
      hideFacilityInfo={facility.hideFacilityInfo}
    />
  );
};

Venues.propTypes = {
  facility: facilityPropType,
};

Venues.defaultProps = {
  facility: emptyFacility,
};

const mapStateToProps = (state) => ({
  facility: state.facilities.currentFacility,
});

const VenuesContainer = compose(
  connect(mapStateToProps),
  reduxForm({
    form: reassignVenueFormName,
    initialValues: {
      facility: "",
    },
  })
)(Venues);

export default VenuesContainer;
