const getBottomBorderWidth = (state) => {
  if (state.menuIsOpen) {
    return "0";
  }
  if (state.isFocused) {
    return "2px";
  }
  return "1px";
};

export const getSelectStyles = (theme, hasError = false) => {
  const {
    borderColor,
    borderColorActive,
    borderRadius,
    fontFamily,
    indicatorColor,
    minHeight,
  } = theme.components.Select;

  return {
    control: (base, state) => ({
      ...base,
      "&:hover": { borderColor: "none" },
      minHeight: minHeight || 38,
      boxShadow: "none",
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      border:
        state.isFocused || state.menuIsOpen
          ? `2px solid ${hasError ? "#d53535" : borderColorActive}`
          : `1px solid ${hasError ? "#d53535" : borderColor}`,
      borderBottomWidth: getBottomBorderWidth(state),
      borderRadius,
      borderBottomLeftRadius: state.menuIsOpen ? "0" : borderRadius,
      borderBottomRightRadius: state.menuIsOpen ? "0" : borderRadius,
      transition: "0 all",
    }),
    singleValue: (base) => ({
      ...base,
      position: "static",
      transform: "none",
      maxWidth: "100%",
      whiteSpace: "normal",
    }),
    placeholder: (base) => ({
      ...base,
      position: "static",
      transform: "none",
      maxWidth: "100%",
      fontFamily,
      color: "#757575",
    }),
    indicatorSeparator: (base) => ({ ...base, display: "none" }),
    clearIndicator: (base) => ({
      ...base,
      color: indicatorColor,
      "&:hover": { color: indicatorColor },
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#231f20" : "#484848",
      display: state.isDisabled ? "none" : "block",
      "&:hover": {
        backgroundColor: "#ecf0f7",
        "&:last-child": {
          borderRadius,
        },
      },
      backgroundColor:
        (state.isSelected && "#ecf0f7") ||
        (state.isFocused && "#ecf0f7") ||
        provided.backgroundColor,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 50,
      marginTop: "0",
      borderRadius,
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      border: `2px solid ${borderColorActive}`,
      borderTop: "none",
      boxShadow: "none",
      "&::after": {
        content: '""',
        position: "absolute",
        zIndex: "-1",
        boxShadow:
          "0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)",
      },
      "&::before": {
        content: '""',
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        zIndex: "-1",
        borderTop: "1px solid hsl(0deg 0% 0% / 10%)",
      },
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
    }),
    valueContainer: (provided) => ({
      ...provided,
      flexWrap: "nowrap",
    }),
  };
};

const customStyles = {
  control: (base, state) => ({
    ...base,
    "&:hover": { borderColor: "none" },
    minHeight: 38,
    boxShadow: "none",
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    border:
      state.isFocused || state.menuIsOpen
        ? "2px solid #9e8146"
        : "1px solid hsl(0, 0%, 80%)",
    borderBottomWidth: getBottomBorderWidth(state),
    borderRadius: "2px",
    transition: "0 all",
  }),
  singleValue: (base) => ({
    ...base,
    position: "static",
    transform: "none",
    maxWidth: "100%",
    whiteSpace: "normal",
  }),
  placeholder: (base) => ({
    ...base,
    position: "static",
    transform: "none",
    maxWidth: "100%",
    fontFamily: "Raleway",
    color: "#757575",
  }),
  indicatorSeparator: (base) => ({ ...base, display: "none" }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#231f20" : "#484848",
    display: state.isDisabled ? "none" : "block",
    "&:hover": {
      backgroundColor: "#ecf0f7",
      "&:last-child": {
        borderRadius: "2px",
      },
    },
    backgroundColor:
      (state.isSelected && "#ecf0f7") ||
      (state.isFocused && "#ecf0f7") ||
      provided.backgroundColor,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 50,
    marginTop: "0",
    borderRadius: "2px",
    border: "2px solid #9e8146",
    borderTop: "none",
    boxShadow: "none",
    "&::after": {
      content: '""',
      position: "absolute",
      zIndex: "-1",
      boxShadow:
        "0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      zIndex: "-1",
      borderTop: "1px solid hsl(0deg 0% 0% / 10%)",
    },
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: "nowrap",
  }),
};

export default customStyles;
