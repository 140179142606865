import PropTypes from "prop-types";
import { cloneDeep } from "lodash";

/**
 *
 * Model used to represent a `FacilityContact`. This file includes
 *
 * 1. An empty model with default strings and values
 * 2. Prop Types for the model
 * 3. A mapper that takes the object provided by the server and converts it to this model
 *
 */

/**
 *
 * EMPTY OBJECTS - use to prefill. For example initial state
 *
 */
const emptyFacilityContact = {
  id: 0,
  email: "",
  role: "",
  fullName: "",
  copyOnEmails: false,
  created: "",
  updated: "",
  facilityId: "",
  facilityAddress: {
    id: 0,
    companyName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "US",
    zip: "",
  },
};

/**
 *
 * PROP TYPES
 *
 */
const addressPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
});
const facilityContactPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  copyOnEmails: PropTypes.bool.isRequired,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  facilityId: PropTypes.string.isRequired,
  facilityAddress: addressPropType,
});

/**
 *
 * MAPPER - MAP from retrieved data to model
 *
 */
const convertServerFacilityContactToContactModel = (contact) => {
  const model = cloneDeep(emptyFacilityContact);
  model.id = contact.id;
  model.fullName = contact.fullName || "";
  model.role = contact.role || "";
  model.email = contact.email;
  model.copyOnEmails = contact.copyOnEmails;
  model.created = contact.created_at;
  model.updated = contact.updated_at;
  model.facilityId = contact.facilityId;

  const facilityAddressDetails = contact.facility.additional_insured_address;
  if (facilityAddressDetails) {
    model.facilityAddress = {
      id: facilityAddressDetails.id,
      companyName: facilityAddressDetails.companyName || "",
      address1: facilityAddressDetails.address1,
      address2: facilityAddressDetails.address2 || "",
      city: facilityAddressDetails.city,
      state: facilityAddressDetails.state,
      country: facilityAddressDetails.country || "US",
      zip: facilityAddressDetails.zip,
    };
  }

  return model;
};

export { facilityContactPropType, convertServerFacilityContactToContactModel };
