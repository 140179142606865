import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { compose } from "redux";
import VenuesContactsSearchResultsTable from "./VenuesContactsSearchResultsTable";
import ProducersContactsSearchResultsTable from "./ProducersContactsSearchResultsTable";
import FacilitiesContactsSearchResultsTable from "./FacilitiesContactsSearchResultsTable";
import {
  VENUE_CONTACT_TYPE,
  PRODUCER_CONTACT_TYPE,
  FACILITY_CONTACT_TYPE,
  BULK_ACTION_OVERWRITE,
  allContactsForm,
} from "../../constants";
import { venueContactPropType } from "../../Helpers/VenueContactModel";
import { producerContactPropType } from "../../Helpers/ProducerContactModel";
import { facilityContactPropType } from "../../Helpers/FacilityContactModel";
import BulkActions from "./BulkActions";
import ModalOverwrite from "./ModalOverwrite";

const formValues = formValueSelector(allContactsForm);

const ResultsContainer = ({
  contactsTableData,
  contactType,
  selectedContacts,
  setSelectedContacts,
  setShowModalRemove,
  toggleAllCheckboxes,
  bulkAction,
  showModalOverwrite,
  setShowModalOverwrite,
  overwriteContacts,
  setBulkActionType,
  isValid,
  resetOverwrite,
  checkedContacts,
  email,
}) => (
  <>
    <BulkActions
      setShowModalRemove={setShowModalRemove}
      selectedContacts={selectedContacts}
      contactsList={contactsTableData}
      setSelectedContacts={setSelectedContacts}
      toggleAllCheckboxes={toggleAllCheckboxes}
      bulkAction={bulkAction}
      setShowModalOverwrite={setShowModalOverwrite}
      checkedContacts={checkedContacts}
    />

    {contactType === VENUE_CONTACT_TYPE && (
      <VenuesContactsSearchResultsTable
        contactsList={contactsTableData}
        setShowModalRemove={setShowModalRemove}
        setSelectedContacts={setSelectedContacts}
        setShowModalOverwrite={setShowModalOverwrite}
        checkedContacts={checkedContacts}
      />
    )}
    {contactType === PRODUCER_CONTACT_TYPE && (
      <ProducersContactsSearchResultsTable
        contactsList={contactsTableData}
        setShowModalRemove={setShowModalRemove}
        setSelectedContacts={setSelectedContacts}
        setShowModalOverwrite={setShowModalOverwrite}
        checkedContacts={checkedContacts}
      />
    )}
    {contactType === FACILITY_CONTACT_TYPE && (
      <FacilitiesContactsSearchResultsTable
        contactsList={contactsTableData}
        setShowModalRemove={setShowModalRemove}
        setSelectedContacts={setSelectedContacts}
        setShowModalOverwrite={setShowModalOverwrite}
        checkedContacts={checkedContacts}
      />
    )}

    <ModalOverwrite
      show={showModalOverwrite}
      action={() => overwriteContacts()}
      handleClose={() => setShowModalOverwrite(false)}
      cancel={() => {
        resetOverwrite();
        setSelectedContacts([]);
      }}
      contactType={contactType}
      singleEmail={email}
      onShow={() => setBulkActionType(BULK_ACTION_OVERWRITE)}
      isValid={isValid}
    />
  </>
);

ResultsContainer.propTypes = {
  contactsTableData: PropTypes.oneOfType([
    PropTypes.arrayOf(producerContactPropType),
    PropTypes.arrayOf(venueContactPropType),
    PropTypes.arrayOf(facilityContactPropType),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
  contactType: PropTypes.string.isRequired,
  setShowModalRemove: PropTypes.func.isRequired,
  selectedContacts: PropTypes.arrayOf(PropTypes.bool).isRequired,
  setSelectedContacts: PropTypes.func.isRequired,
  toggleAllCheckboxes: PropTypes.func.isRequired,
  bulkAction: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  showModalOverwrite: PropTypes.bool.isRequired,
  setShowModalOverwrite: PropTypes.func.isRequired,
  overwriteContacts: PropTypes.func.isRequired,
  setBulkActionType: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  resetOverwrite: PropTypes.func.isRequired,
  checkedContacts: PropTypes.arrayOf(PropTypes.bool).isRequired,
  email: PropTypes.string,
};

ResultsContainer.defaultProps = {
  email: null,
};

const mapStateToProps = (state) => ({
  contactsTableData: state.contacts.contactsTableData,
  selectedContacts: formValues(state, "selectedContacts") || [],
  bulkAction: formValues(state, "bulkAction") || {},
  isValid:
    (!!formValues(state, "overwriteName") ||
      !!formValues(state, "overwriteRole") ||
      !!formValues(state, "overwriteEmail") ||
      (!!formValues(state, "overwriteCopyOnEmails") &&
        formValues(state, "overwriteCopyOnEmails").value !== "dnc")) &&
    !(
      state.form.allContactsForm &&
      state.form.allContactsForm.syncErrors &&
      state.form.allContactsForm.syncErrors.overwriteEmail
    ),
  checkedContacts: formValues(state, "selectedContacts") || [],
  initialValues: {
    selectedContacts: state.contacts.contactsTableData.map(() => false),
  },
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: allContactsForm,
    enableReinitialize: true,
  })
)(ResultsContainer);
