export const GLL_1 = { occurrence: 300000, aggregate: 600000 };
export const GLL_2 = { occurrence: 500000, aggregate: 500000 };
export const GLL_3 = { occurrence: 500000, aggregate: 1000000 };
export const GLL_4 = { occurrence: 1000000, aggregate: 1000000 };
export const GLL_5 = { occurrence: 1000000, aggregate: 2000000 };

export const allLimits = [GLL_1, GLL_2, GLL_3, GLL_4, GLL_5];

export const MEDICAL_LIMIT = 5000;
export const DEDUCTIBLE_LIMIT_AMOUNT = 0;
export const DRP_1 = 300000;

export const GL_LABELS = [
  "$300,000/$600,000 (default)",
  "$500,000/$500,000",
  "$500,000/$1,000,000",
  "$1,000,000/$1,000,000",
  "$1,000,000/$2,000,000",
];
