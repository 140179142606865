import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { loadStates } from "../../Actions/actions";

class LoadOrder extends Component {
  componentDidMount() {
    const { action } = this.props;
    action();
  }

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

LoadOrder.propTypes = {
  children: PropTypes.node,
  action: PropTypes.func.isRequired,
};

LoadOrder.defaultProps = {
  children: <></>,
};

const mapDispatchToProps = (dispatch) => ({
  action: () => dispatch(loadStates()),
});

export default connect(null, mapDispatchToProps)(LoadOrder);
