import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getFacilityId, getVenueId } from "../../Helpers/URLParser";
import { getVenue, clearVenueData } from "../../Actions/actions";
import { emptyVenue, venuePropType } from "../../Helpers/VenueModel";

class LoadVenue extends Component {
  componentDidMount() {
    const { facilityId, venueId, loadVenue } = this.props;
    loadVenue(facilityId, venueId);
  }

  componentWillUnmount() {
    const { clearVenue } = this.props;
    clearVenue();
  }

  render() {
    const { children, venue } = this.props;
    return venue.id === emptyVenue.id ? <></> : <>{children}</>;
  }
}

LoadVenue.propTypes = {
  children: PropTypes.node,
  facilityId: PropTypes.number.isRequired,
  venueId: PropTypes.number.isRequired,
  venue: venuePropType.isRequired,
  loadVenue: PropTypes.func.isRequired,
  clearVenue: PropTypes.func.isRequired,
};

LoadVenue.defaultProps = {
  children: <></>,
};

const mapStateToProps = (state) => ({
  facilityId: getFacilityId(window.location.pathname),
  venueId: getVenueId(window.location.pathname),
  venue: state.venues.currentVenue,
});

const mapDispatchToProps = (dispatch) => ({
  loadVenue: (facilityId, venueId) => dispatch(getVenue(facilityId, venueId)),
  clearVenue: () => dispatch(clearVenueData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadVenue);
