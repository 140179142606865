import { connect } from "react-redux";
import { reduxForm, reset, formValueSelector, getFormValues } from "redux-form";
import { compose } from "redux";
import { insuranceContactForm } from "../../constants";
import InsuranceContact from "./InsuranceContact";
import { setFormValues, getPolicy } from "../../Actions/actions";
import CoverageService from "../../Helpers/CoverageService";
import API from "../../Helpers/API";

const formValues = formValueSelector(insuranceContactForm);
const allFormValues = getFormValues(insuranceContactForm);

const mapStateToProps = (state) => ({
  states: state.app.states,
  coverage: state.policies.currentCoverage,
  insuranceContactType:
    formValues(state, "insuranceContactType") ||
    (state.policies.currentCoverage || { insuranceContactType: "" })
      .insuranceContactType,
  contactSame:
    formValues(state, "contactSame") ||
    state.policies.currentCoverage.contactSame,
});

const mapDispatchToProps = (dispatch) => ({
  update: () =>
    dispatch((_, getState) => {
      const state = getState();
      const values = allFormValues(state);
      const coverage = state.policies.currentCoverage;
      const contactSame = values.contactSame === "yes";

      const insuranceContactType =
        values.insuranceContactType === "Person"
          ? values.insuranceContactType
          : "Other";
      const insuranceContactCompanyName =
        values.insuranceContactType !== "Person"
          ? values.insuranceContactCompanyName
          : null;

      const data = {
        id: coverage.id,
        coverageApplication: {
          insuranceContactPhone: values.insuranceContactPhone,
          insuranceContactEmail: values.insuranceContactEmail,
          insurance_contact_address: {
            contactFirstName: values.insuranceContactFirstName,
            contactLastName: values.insuranceContactLastName,
            address1: contactSame
              ? coverage.renterAddress1
              : values.insuranceContactAddress1,
            city: contactSame
              ? coverage.renterCity
              : values.insuranceContactCity,
            state: contactSame
              ? coverage.renterState
              : values.insuranceContactState,
            country: contactSame
              ? coverage.renterCountry
              : values.insuranceContactCountry,
            zip: contactSame ? coverage.renterZip : values.insuranceContactZip,
            contactType: contactSame
              ? coverage.renterContactType
              : insuranceContactType,
            companyName: contactSame
              ? coverage.renterCompanyName
              : insuranceContactCompanyName,
          },
        },
      };
      return new CoverageService(new API())
        .putCoverageEditInsuranceContact(data)
        .then(() => dispatch(getPolicy(coverage.id)));
    }),
  edit: (coverage) => {
    dispatch(reset(insuranceContactForm));
    dispatch(
      setFormValues(insuranceContactForm, {
        insuranceContactPhone: coverage.insuranceContactPhone,
        insuranceContactEmail: coverage.insuranceContactEmail,
        insuranceContactType: coverage.insuranceContactType,
        insuranceContactFirstName: coverage.insuranceContactFirstName,
        insuranceContactLastName: coverage.insuranceContactLastName,
        insuranceContactCompanyName: coverage.insuranceContactCompanyName,
        insuranceContactAddress1: coverage.insuranceContactAddress1,
        insuranceContactCity: coverage.insuranceContactCity,
        insuranceContactState: coverage.insuranceContactState,
        insuranceContactZip: coverage.insuranceContactZip,
        insuranceContactCountry: coverage.insuranceContactCountry,
        contactSame: coverage.contactSame,
      })
    );
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  edit: () => dispatchProps.edit(stateProps.coverage),
});

const InsuranceContactContainer = compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: insuranceContactForm,
    initialValues: { emailInsuranceContact: true },
  })
)(InsuranceContact);

export default InsuranceContactContainer;
