import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getProducerId } from "../../Helpers/URLParser";
import { getProducer, clearProducerData } from "../../Actions/actions";
import { emptyProducer, producerPropType } from "../../Helpers/ProducerModel";

class LoadProducer extends Component {
  componentDidMount() {
    const { id, loadProducer } = this.props;
    loadProducer(id);
  }

  componentWillUnmount() {
    const { clearProducer } = this.props;
    clearProducer();
  }

  render() {
    const { children, producer } = this.props;
    return producer.id === emptyProducer.id ? <></> : <>{children}</>;
  }
}

LoadProducer.propTypes = {
  children: PropTypes.node,
  id: PropTypes.number.isRequired,
  producer: producerPropType.isRequired,
  loadProducer: PropTypes.func.isRequired,
  clearProducer: PropTypes.func.isRequired,
};

LoadProducer.defaultProps = {
  children: <></>,
};

const mapStateToProps = (state) => ({
  id: getProducerId(window.location.pathname),
  producer: state.producers.currentProducer,
});

const mapDispatchToProps = (dispatch) => ({
  loadProducer: (id) => dispatch(getProducer(id)),
  clearProducer: () => dispatch(clearProducerData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadProducer);
