import styled from "styled-components";
import { IntegerNumberInput } from "@jauntin/react-ui";

const StyledIntegerInput = styled(IntegerNumberInput)`
  input {
    ${({ theme }) => theme.components.Input?.customCss}
  }
`;

export default StyledIntegerInput;
