import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, ProgressBar } from "react-bootstrap";
import getReadableFileSize from "../../Helpers/ReadableFileSize";

const DropzonePreview = ({
  meta,
  fileWithMeta,
  submitting,
  clearAllAlerts,
}) => {
  const { name, percent, status, size } = meta;
  const { remove } = fileWithMeta;

  return (
    <>
      <div className="dropzone__preview">
        <Form.Row className="align-items-center">
          <Col xs="auto">
            <i className="fal fa-file-excel dropzone__preview-img" />
          </Col>
          <Col>
            <div className="dropzone__filename">{name}</div>
            <Form.Row className="align-items-center">
              <Col>
                <ProgressBar now={Math.round(percent)} />
                <Form.Row className="align-items-center justify-content-between mt-2">
                  <Col xs="auto">{getReadableFileSize(size)}</Col>
                  <Col xs="auto">{Math.round(percent)}% uploaded</Col>
                </Form.Row>
              </Col>
              <Col xs="auto" className="mb-auto">
                <i
                  className={`fal fa-check-circle dropzone__done-icon ${
                    status !== "done" ? "invisible" : ""
                  }`}
                />
              </Col>
            </Form.Row>
          </Col>
        </Form.Row>
        {[
          "error_upload_params",
          "exception_upload",
          "error_upload",
          "aborted",
          "ready",
          "done",
        ].includes(status) &&
          !submitting && (
            <Button
              variant="outline-primary"
              onClick={() => {
                remove();
                clearAllAlerts();
              }}
              className="dropzone__reset"
            >
              Remove file
            </Button>
          )}
      </div>
    </>
  );
};

DropzonePreview.propTypes = {
  meta: PropTypes.shape({
    name: PropTypes.string,
    percent: PropTypes.number,
    size: PropTypes.number,
    status: PropTypes.string,
  }).isRequired,
  fileWithMeta: PropTypes.shape({
    remove: PropTypes.func,
  }).isRequired,
  submitting: PropTypes.bool.isRequired,
  clearAllAlerts: PropTypes.func.isRequired,
};

export default DropzonePreview;
