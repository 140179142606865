import styled from "styled-components";
import { PhoneInput } from "@jauntin/react-ui";

const PhoneField = styled(PhoneInput)`
  input {
    ${({ theme }) => theme.components.Input?.customCss}
  }
`;

export default PhoneField;
