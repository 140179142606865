const duplicateIndices = (a) => {
  const duplicates = [];
  const reversed = [...a].reverse();
  a.forEach((v, i) => {
    if (a.indexOf(v) !== i || reversed.indexOf(v) !== a.length - i - 1) {
      duplicates.push(i);
    }
  });
  return duplicates;
};

export { duplicateIndices };
