import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { Field } from "redux-form";
import TextField from "./TextField";
import SelectListField from "./SelectListField/SelectListField";
import { companyTypeOptions } from "../../Constants/companyTypeOptions";
import { required } from "@gbli-events/common/src/Helpers/validators";
import themePropType from "../../Models/Theme";
import { GBLI_THEME } from "../../Constants/themes/gbliTheme";

const propTypes = {
  companyNameControl: PropTypes.string.isRequired,
  companyTypeControl: PropTypes.string.isRequired,
  theme: themePropType,
};

const CompanyFieldGroup = ({
  companyNameControl,
  companyTypeControl,
  theme,
}) => {
  return (
    <>
      <Form.Group>
        <Field
          component={TextField}
          label="Company/Organization Name"
          placeholder="Company/Organization Name"
          ariaLabel="Company/Organization Name"
          validate={[required]}
          required
          name={companyNameControl}
        />
      </Form.Group>
      <Form.Group>
        <div className="form-row align-items-center">
          <Field
            component={SelectListField}
            className="mw--300 col-sm"
            label="Type of Company"
            placeholder="Select company type"
            name={companyTypeControl}
            required
            searchable
            options={companyTypeOptions}
            theme={theme}
          />
        </div>
      </Form.Group>
    </>
  );
};

CompanyFieldGroup.propTypes = propTypes;
CompanyFieldGroup.defaultProps = {
  theme: GBLI_THEME,
};

export default CompanyFieldGroup;
