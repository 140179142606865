const getChecklistIds = (checklist, valuesList) => {
  if (checklist && checklist.includes(true)) {
    const sendList = checklist
      .map((contact, i) =>
        contact === true && valuesList[i] ? valuesList[i].id : null
      )
      .filter((a) => a !== null);
    return sendList;
  }
  return [];
};

export default getChecklistIds;
