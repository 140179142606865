export const getThemeColor = (theme, variant = "primary", shade = "main") => {
  return theme.palette[variant]?.[shade] || theme.palette?.primary?.main;
};

export const getFont = (theme, variant = "primary") => {
  return theme.typography?.fonts[variant] || theme.typography?.font?.primary;
};

export const getMediaBreakpointUp = (breakpoint) =>
  breakpoint && `@media (min-width: ${breakpoint})`;

export const getMediaBreakpointDown = (breakpoint) =>
  breakpoint && `@media (max-width: ${breakpoint})`;
