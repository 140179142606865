import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import StyledCheckboxField from "@gbli-events/common/src/Components/FormElements/CheckboxField/StyledCheckboxField";
import { TimezoneFormatted } from "@jauntin/react-ui";
import { dateHelpers } from "@jauntin/utilities";

const CheckboxDates = ({ controls, controlName, label, className, disabled }) =>
  controls.length ? (
    <div className={className}>
      <div className="label mb-2">{label}</div>
      <div className="d-flex flex-wrap">
        {controls.map((control, index) => (
          <Field
            key={index}
            className="mr-5 mb-2 mb-md-0"
            component={StyledCheckboxField}
            name={`${controlName}[${index}].selected`}
            label={
              <TimezoneFormatted
                date={control.key}
                format={dateHelpers.SLASH_SEPARATED_FORMAT}
              />
            }
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  ) : (
    <></>
  );

CheckboxDates.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  controls: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      value: PropTypes.any,
    })
  ).isRequired,
  controlName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

CheckboxDates.defaultProps = {
  className: "",
  disabled: false,
};

export default CheckboxDates;
